<template>
  <div class="main">
    <div class="header">
      <a-select
        v-model:value="type"
        style="width: 200px"
        @change="handleTypeChange"
      >
        <a-select-option v-for="option in typeOptions" :key="option.value" :value="option.value">{{ option.text }}
        </a-select-option>
      </a-select>
      <a-input v-model:value="beginDate" placeholder="开始日期" style="width: 100px"/>
      <a-input v-model:value="endDate" placeholder="结束日期" style="width: 100px"/>
      <a-button type="primary" @click="onUpdateData">上传</a-button>
      <a-button type="primary" @click="onReload">刷新</a-button>
    </div>
    <div class="content">
      <a-table
        :columns="columns"
        :row-key="record => record.id"
        :data-source="dataSource"
        :pagination="pagination"
        :loading="loading"
        :locale="tbLocale"
        :scroll="{ y: '75vh' }"
        @change="handleTableChange"
        :bordered="true"
      >
        <template
          #customFilterDropdown="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }"
        >
          <div style="padding: 8px">
            <a-input
              ref="searchInput"
              :placeholder="`搜索 ${column.title}`"
              :value="selectedKeys[0]"
              style="width: 128px; margin-bottom: 8px; display: block"
              @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
              @pressEnter="handleSearch(selectedKeys, confirm, column.dataIndex)"
            />
            <a-button size="small" style="width: 60px; margin-right: 8px" @click="handleReset(clearFilters)">
              重置
            </a-button>
            <a-button
              type="primary"
              size="small"
              style="width: 60px;"
              @click="handleSearch(selectedKeys, confirm, column.dataIndex)"
            >
              <template #icon>
                <SearchOutlined/>
              </template>
              搜索
            </a-button>

          </div>
        </template>
        <template #customFilterIcon="{ filtered }">
          <search-outlined :style="{ fontSize:'15px',color: filtered ? '#108ee9' : undefined }"/>
        </template>
        <template #bodyCell="{ column, record }">
          <template v-if="column.dataIndex === 'type'">
            <div v-if="record.type==1">门(急)诊病历记录</div>
            <div v-if="record.type==2">门(急)诊处方记录</div>
            <div v-if="record.type==3">门(急)诊处方明细</div>
            <div v-if="record.type==4">门(急)诊费用结算</div>
            <div v-if="record.type==5">门(急)诊费用明细</div>
            <div v-if="record.type==6">门(急)诊检查报告</div>
            <div v-if="record.type==7">门(急)诊检查申请单</div>
            <div v-if="record.type==8">门(急)诊检验报告</div>
            <div v-if="record.type==9">门(急)诊检验常规报告</div>
            <div v-if="record.type==10">门(急)诊检验申请单</div>
            <div v-if="record.type==11">门(急)诊检验细菌报告</div>
            <div v-if="record.type==12">门(急)诊检验药敏报告</div>
          </template>
        </template>
      </a-table>
    </div>
  </div>
</template>

<script>
  import {defineComponent, onMounted, reactive, toRefs, ref} from 'vue';
  import {useStore} from 'vuex';
  import {SearchOutlined} from '@ant-design/icons-vue';
  import dayjs, {Dayjs} from 'dayjs'
  import {message} from 'ant-design-vue';

  export default defineComponent({
    name: 'DataUpload',
    components: {
      SearchOutlined
    },
    setup() {
      const store = useStore();
      const searchInput = ref();
      const types = [{
        text: '门(急)诊病历记录',
        value: '1'
      }, {
        text: '门(急)诊处方记录',
        value: '2'
      }, {
        text: '门(急)诊处方明细',
        value: '3'
      }, {
        text: '门(急)诊费用结算',
        value: '4'
      }, {
        text: '门(急)诊费用明细',
        value: '5'
      }, {
        text: '门(急)诊检查报告',
        value: '6'
      }, {
        text: '门(急)诊检查申请单',
        value: '7'
      }, {
        text: '门(急)诊检验报告',
        value: '8'
      }, {
        text: '门(急)诊检验常规报告',
        value: '9'
      }, {
        text: '门(急)诊检验申请单',
        value: '10'
      }, {
        text: '门(急)诊检验细菌报告',
        value: '11'
      }, {
        text: '门(急)诊检验药敏报告',
        value: '12'
      }];
      const columns = [
        {
          title: '编号',
          dataIndex: 'id',
          width: '60px',
          sortDirections: ['descend', 'ascend'],
          showSorterTooltip: false,
          sorter: true,
          defaultSortOrder: 'descend'
        },
        {
          title: '业务名称',
          dataIndex: 'type',
          width: '150px',
          sorter: true,
          sortDirections: ['descend', 'ascend'],
          showSorterTooltip: false,
          filters: types
        }, {
          title: '开始日期',
          dataIndex: 'beginDate',
          width: '100px'
        }, {
          title: '结束日期',
          dataIndex: 'endDate',
          width: '100px'
        }, {
          title: '结果',
          dataIndex: 'msg',
          width: '100px'
        }
      ];
      const data = reactive({
        typeOptions: types,
        tbLocale: {
          filterConfirm: '确定',
          filterReset: '重置',
          emptyText: '暂无数据'
        },
        columns,
        type: '1',
        loading: false,
        pagination: {
          total: 0,
          pageSize: 10,
          page: 1,
          showSizeChanger: true
        },
        dataSource: [],
        beginDate: '20220729',
        endDate: '20220729'
      });
      // beginDate: dayjs().format("YYYYMMDD"),
      //   endDate: dayjs().format("YYYYMMDD")
      const queryData = params => {
        store.dispatch('his/queryHisDataUploadInfo', params).then(res => {
          data.loading = false;
          if (res && res.data) {
            data.pagination.total = res.data.data.total;
            if (data.pagination.total > 0) {
              data.dataSource.push(...res.data.data.list);
            }
          }
        });
      };
      const handleTableChange = (pag, filters, sorter) => {
        data.dataSource = [];
        data.loading = true;
        data.pagination.page = pag?.current;
        data.pagination.pageSize = pag?.pageSize;
        data.pagination.total = 0;
        queryData({
          pageSize: pag.pageSize,
          page: pag?.current,
          sortField: sorter.field,
          sortOrder: sorter.order,
          ...filters
        });
      };
      const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
      };
      const handleReset = clearFilters => {
        clearFilters({
          confirm: true
        });
        // state.searchText = '';
      };
      onMounted(() => {
        queryData({
          pageSize: data.pagination.pageSize,
          page: data.pagination.page,
          sortField: 'id',
          sortOrder: 'descend'
        });
      });
      const onUpdateData = () => {
        const params = {
          type: data.type,
          beginDate: data.beginDate,
          endDate: data.endDate
        };
        store.dispatch('his/doHisDataUpload', params).then(res => {
          if (res && res.data) {
            if(res.data.success){
              onReload();
              message.success("上传成功");
            }else{
              message.error("上传失败")
            }
          }
          onReload();
        });
      };
      const onReload = () => {
        data.dataSource = [];
        data.loading = true;
        data.pagination.pageSize = 10;
        data.pagination.page = 1;
        queryData({
          pageSize: data.pagination.pageSize,
          page: data.pagination.page,
          sortField: 'id',
          sortOrder: 'descend'
        });
      };
      const handleTypeChange = value => {
        data.type = value;
      };
      return {
        ...toRefs(data),
        searchInput,
        handleTableChange,
        handleSearch,
        handleReset,
        onUpdateData,
        onReload,
        handleTypeChange
      };
    }
  });
</script>

<style lang="scss" scoped>
  @import '@/style/his/dataUpload.scss';
</style>

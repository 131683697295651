import {createApp} from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import VueParticles from 'vue-particles';
import './assets/iconfont/iconfont.css';
import 'ant-design-vue/dist/antd.css';
import 'amfe-flexible';
import Antd, {ConfigProvider} from 'ant-design-vue/es';

const app = createApp(App);
app.use(VueParticles).use(Antd).use(ConfigProvider).use(store).use(router).mount('#app');

<template>
  <div class="main">
    <div class="header">
      <a-button type="primary" @click="onFullscreenClick">全屏显示</a-button>
    </div>
    <div class="content" id="fullDiv">
      <div class="content-top">
        {{title&&title!=''?title:'全民健康普查体检数据展示'}}
      </div>
      <div class="content-main">
        <div class="content-main-left">
          <div class="content-main-left-top">
            <div class="content-main-left-top-title">全民健康普查数据</div>
            <div class="chart" id="myChartDom"></div>
            <div class="chart" id="myChartDom1"></div>
            <div class="chart-age tabs">
              <div class="tabs-title">健康风险项（按年龄段）</div>
              <a-tabs v-model:activeKey="activeKey" tabPosition="left" @change="onTabChange">
                <a-tab-pane key="1" tab="60岁以上" forceRender>
                  <div class="chart-age" id="chartAge1"></div>
                </a-tab-pane>
                <a-tab-pane key="2" tab="51-60岁" forceRender>
                  <div class="chart-age" id="chartAge2"></div>
                </a-tab-pane>
                <a-tab-pane key="3" tab="41-50岁" forceRender>
                  <div class="chart-age" id="chartAge3"></div>
                </a-tab-pane>
                <a-tab-pane key="4" tab="31-40岁" forceRender>
                  <div class="chart-age" id="chartAge4"></div>
                </a-tab-pane>
                <a-tab-pane key="5" tab="30岁以下" forceRender>
                  <div class="chart-age" id="chartAge5"></div>
                </a-tab-pane>
              </a-tabs>
            </div>
            <div class="chart-age tabs">
              <div class="tabs-title">亚健康状况（按年龄段）</div>
              <a-tabs v-model:activeKey="activeKey" tabPosition="left" @change="onTabChange">
                <a-tab-pane key="1" tab="60岁以上" forceRender>
                  <div class="chart-age" id="chartAgeSub1"></div>
                </a-tab-pane>
                <a-tab-pane key="2" tab="51-60岁" forceRender>
                  <div class="chart-age" id="chartAgeSub2"></div>
                </a-tab-pane>
                <a-tab-pane key="3" tab="41-50岁" forceRender>
                  <div class="chart-age" id="chartAgeSub3"></div>
                </a-tab-pane>
                <a-tab-pane key="4" tab="31-40岁" forceRender>
                  <div class="chart-age" id="chartAgeSub4"></div>
                </a-tab-pane>
                <a-tab-pane key="5" tab="30岁以下" forceRender>
                  <div class="chart-age" id="chartAgeSub5"></div>
                </a-tab-pane>
              </a-tabs>
            </div>
          </div>
          <div class="content-main-left-bottom">
            <div class="content-main-left-bottom-chart">
              <div class="content-main-left-bottom-chart-title">合作体检中心检测量排名</div>
              <div class="content-main-left-bottom-chart-content">
                <div class="chart-unit" ref="chartUnit1"></div>
              </div>
            </div>
            <div class="content-main-left-bottom-chart">
              <div class="content-main-left-bottom-chart-title">合作医院送诊预约服务排名</div>
              <div class="content-main-left-bottom-chart-content">
                <div class="chart-unit" ref="chartUnit2"></div>
              </div>
            </div>
            <div class="content-main-left-bottom-chart">
              <div class="content-main-left-bottom-chart-title">合作企业服务接诊排名</div>
              <div class="content-main-left-bottom-chart-content">
                <div class="chart-unit" ref="chartUnit3"></div>
              </div>
            </div>
            <div class="content-main-left-bottom-chart">
              <div class="content-main-left-bottom-chart-title">合作专家义诊服务排名</div>
              <div class="content-main-left-bottom-chart-content">
                <div class="chart-unit" ref="chartUnit4"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="content-main-right">
          <div class="content-main-right-title">全民健康主题活动</div>
          <div class="content-main-right-table">
            <div class="content-main-right-table-content">
              <div class="content-main-right-table-content-column-title">
                <div class="content-main-right-table-content-column-cell1">活动主题</div>
                <div class="content-main-right-table-content-column-cell2">举办量</div>
                <div class="content-main-right-table-content-column-cell3">排名</div>
              </div>
              <div class="content-main-right-table-content-column" v-for="(item,index) in activityData.holdList"
                   :key="index">
                <div class="content-main-right-table-content-column-cell1">{{item.name}}</div>
                <div class="content-main-right-table-content-column-cell2">{{item.holdNum}}</div>
                <div class="content-main-right-table-content-column-cell3">{{index+1}}</div>
              </div>
            </div>
            <div class="content-main-right-table-content">
              <div class="content-main-right-table-content-column-title">
                <div class="content-main-right-table-content-column-cell1">活动主题</div>
                <div class="content-main-right-table-content-column-cell2">满意度</div>
                <div class="content-main-right-table-content-column-cell3">排名</div>
              </div>
              <div class="content-main-right-table-content-column" v-for="(item,index) in activityData.satisfactionList"
                   :key="index">
                <div class="content-main-right-table-content-column-cell1">{{item.name}}</div>
                <div class="content-main-right-table-content-column-cell2">{{item.satisfaction}}</div>
                <div class="content-main-right-table-content-column-cell3">{{index+1}}</div>
              </div>
            </div>
          </div>
          <div class="content-main-right-table">
            <div class="content-main-right-table-content">
              <div class="content-main-right-table-content-column-title1" style="line-height: 60px;">
                <div class="content-main-right-table-content-column-cell1">活动主题</div>
                <div class="content-main-right-table-content-column-cell2">转换量</div>
                <div class="content-main-right-table-content-column-cell3">排名</div>
              </div>
              <div class="content-main-right-table-content-column" v-for="(item,index) in activityData.convertList"
                   :key="index">
                <div class="content-main-right-table-content-column-cell1">{{item.name}}</div>
                <div class="content-main-right-table-content-column-cell2">{{item.convertNum}}</div>
                <div class="content-main-right-table-content-column-cell3">{{index+1}}</div>
              </div>
            </div>
            <div class="content-main-right-table-content">
              <div class="content-main-right-table-content-column-title1">
                <div class="content-main-right-table-content-column-cell1" style="margin-top: 15px;">主办方</div>
                <div class="content-main-right-table-content-column-cell2" style="width: 120px;">全民学中医授课受欢迎度</div>
                <div class="content-main-right-table-content-column-cell3" style="margin-top: 15px;">排名</div>
              </div>
              <div class="content-main-right-table-content-column" v-for="(item,index) in otherData.zy" :key="index">
                <div class="content-main-right-table-content-column-cell1">{{item.name}}</div>
                <div class="content-main-right-table-content-column-cell2">{{item.num}}</div>
                <div class="content-main-right-table-content-column-cell3">{{index+1}}</div>
              </div>
            </div>
          </div>
          <div class="content-main-right-chart">
            <div class="content-main-right-chart-title">名医接诊量和服务满意度排名</div>
            <div class="content-main-right-chart-content">
              <div class="chart-other" ref="chartOther1"></div>
            </div>
          </div>
          <div class="content-main-right-chart">
            <div class="content-main-right-chart-title">5G中心服务器械产品使用次数和满意度排名</div>
            <div class="content-main-right-chart-content">
              <div class="chart-other" ref="chartOther2"></div>
            </div>
          </div>
          <div class="content-main-right-chart">
            <div class="content-main-right-chart-title">5G中心全民医药保健滋补产品购买排名和总成交价排名</div>
            <div class="content-main-right-chart-content">
              <div class="chart-other" ref="chartOther3"></div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import {defineComponent, onMounted, reactive, toRefs, ref} from 'vue';
import {useStore} from 'vuex';
import * as echarts from 'echarts';
import screenfull from 'screenfull';
import {message} from 'ant-design-vue';

export default defineComponent({
  name: 'Personnel',
  setup() {
    const store = useStore();
    const chartUnit1 = ref();
    const chartUnit2 = ref();
    const chartUnit3 = ref();
    const chartUnit4 = ref();

    const chartOther1 = ref();
    const chartOther2 = ref();
    const chartOther3 = ref();
    const fullDiv = ref();

    const data = reactive({
      isFull: false,
      activeKey: '1',
      timerId: null,
      ageList: ['60岁以上', '51-60岁', '41-50岁', '31-40岁', '30岁以下'],
      encodeList: [
        {
          x: 'conveyingNum',
          y: 'name'
        }, {
          x: 'convertNum',
          y: 'name'
        }, {
          x: 'successNum',
          y: 'name'
        }, {
          x: 'successNum',
          y: 'name'
        }
      ],
      healthData: {},
      subHealthData: {},
      ageHealthDataList: {},
      ageSubHealthDataList: {},
      unitList: {},
      activityData: {},
      otherData: {},
      title: ''
    });
    /*
     * const labelOption = {
     *   show: false,
     *   position: 'insideBottom',
     *   distance: 15,
     *   align: 'left',
     *   verticalAlign: 'middle',
     *   rotate: 90,
     *   formatter: '{c}  {name|{a}}',
     *   fontSize: 10,
     *   rich: {
     *     name: {}
     *   }
     * };
     */
    /*
     * const labelOption = {
     *   show: true,
     *   position: 'top',
     *   fontSize: 10,
     *   color: '#ffffff'
     * };
     */
    const queryData = params => {
      store.dispatch('chart/queryChartData', params).then(res => {
        if (res && res.data.success) {
          data.healthData = res.data.data.healthData;
          data.subHealthData = res.data.data.subHealthData;
          data.ageHealthDataList = res.data.data.ageHealthData;
          data.ageSubHealthDataList = res.data.data.ageSubHealthData;
          data.unitList = res.data.data.unitData;
          data.activityData = res.data.data.activityData;
          data.otherData = res.data.data.otherData;
          data.title = res.data.data.title;
          initMyCharts();
          initMyCharts1();
          initAgeHealthCharts();
          initAgeSubHealthCharts();
          initUnitCharts(1, chartUnit1);
          initUnitCharts(2, chartUnit2);
          initUnitCharts(3, chartUnit3);
          initUnitCharts(4, chartUnit4);
          initOtherChart(data.otherData.my, chartOther1);
          initOtherChart(data.otherData.qx, chartOther2);
          initOtherChart(data.otherData.bj, chartOther3);
          data.timerId = setInterval(changeTab, 5000);
        }
      });
    };
    const initMyCharts = () => {
      const myChartDom = document.getElementById('myChartDom');
      const myChart = echarts.init(myChartDom);
      const option = {
        title: {
          text: '健康风险项（按疾病种类）',
          x: 'center',
          textStyle: {
            color: '#ffffff',
            fontSize: 20
          }
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        dataset: [
          {
            /*
             * 这里指定了维度名的顺序，从而可以利用默认的维度到坐标轴的映射。
             * 如果不指定 dimensions，也可以通过指定 series.encode 完成映射，参见后文。
             */
            // dimensions: ['convertNum', 'conveyingNum', 'id', 'name', 'opType', 'successNum', 'type'],
            source: data.healthData
          }
        ],
        grid: {
          left: '40%',
          right: '2%',
          top: '10%'
        },
        yAxis: [
          {
            axisLabel: {
              fontSize: 12,
              color: '#ffffff',
              formatter(value) {
                return value.replace('\n', '');
                /*
                 * var ret = ""; //拼接加\n返回的类目项
                 * var maxLength = 18; //每项显示文字个数
                 * var valLength = valueDetal.length; //X轴类目项的文字个数
                 * var rowN = Math.ceil(valLength / maxLength); //类目项需要换行的行数
                 * if (rowN > 1) { //如果类目项的文字大于3,
                 *   for (var i = 0; i < rowN; i++) {
                 *     var temp = ""; //每次截取的字符串
                 *     var start = i * maxLength; //开始截取的位置
                 *     var end = start + maxLength; //结束截取的位置
                 *     temp = valueDetal.substring(start, end) + "\n";
                 *     ret += temp;
                 *   }
                 *   return ret;
                 * } else {
                 *   return valueDetal;
                 * }
                 */
              }
            },
            axisTick: {
              show: false
            },
            axisLine: {
              show: true
            },
            z: 10,
            type: 'category',
            inverse: true
          }
        ],
        xAxis: [
          {
            type: 'value',
            splitLine: {
              show: false
            },
            axisLabel: {
              show: false,
              color: '#ffffff',
              fontSize: 14
            }
          }
        ],
        series: [
          {
            realtimeSort: true,
            type: 'bar',
            // barWidth: 30,
            encode: {
              x: 'value',
              y: 'name'
            },
            itemStyle: {
              normal: {
                color() {
                  return '#' + Math.floor(Math.random() * (256 * 256 * 256 - 1)).toString(16);
                }
              }
            },
            label: {
              show: true,
              position: 'right',
              fontSize: 10,
              color: '#ffffff'
            }
          }
        ]
      };
      myChart.setOption(option);
    };
    const initMyCharts1 = () => {
      const myChartDom = document.getElementById('myChartDom1');
      const myChart = echarts.init(myChartDom);
      /*
       * const seriesData = data.subHealthData.seriesData;
       * const legendData = data.subHealthData.legendData;
       * const xAxisData = data.subHealthData.xAxisData;
       * const series = [];
       * legendData.map((disease) => {
       *   series.push({
       *     name: disease,
       *     type: 'bar',
       *     label: labelOption,
       *     emphasis: {
       *       focus: 'series'
       *     },
       *     data: seriesData[disease]
       *   });
       * });
       */
      const option = {
        title: {
          text: '亚健康状况（按疾病种类）',
          x: 'center',
          textStyle: {
            color: '#ffffff',
            fontSize: 20
          }
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        dataset: [
          {
            /*
             * 这里指定了维度名的顺序，从而可以利用默认的维度到坐标轴的映射。
             * 如果不指定 dimensions，也可以通过指定 series.encode 完成映射，参见后文。
             */
            // dimensions: ['convertNum', 'conveyingNum', 'id', 'name', 'opType', 'successNum', 'type'],
            source: data.subHealthData
          }
        ],
        grid: {
          left: '40%',
          right: '2%',
          top: '10%'
        },
        yAxis: [
          {
            axisLabel: {
              fontSize: 12,
              color: '#ffffff',
              formatter(value) {
                return value.replace('\n', '');
                /*
                 * var ret = ""; //拼接加\n返回的类目项
                 * var maxLength = 18; //每项显示文字个数
                 * var valLength = valueDetal.length; //X轴类目项的文字个数
                 * var rowN = Math.ceil(valLength / maxLength); //类目项需要换行的行数
                 * if (rowN > 1) { //如果类目项的文字大于3,
                 *   for (var i = 0; i < rowN; i++) {
                 *     var temp = ""; //每次截取的字符串
                 *     var start = i * maxLength; //开始截取的位置
                 *     var end = start + maxLength; //结束截取的位置
                 *     temp = valueDetal.substring(start, end) + "\n";
                 *     ret += temp;
                 *   }
                 *   return ret;
                 * } else {
                 *   return valueDetal;
                 * }
                 */
              }
            },
            axisTick: {
              show: false
            },
            axisLine: {
              show: true
            },
            z: 10,
            type: 'category',
            inverse: true
          }
        ],
        xAxis: [
          {
            type: 'value',
            splitLine: {
              show: false
            },
            axisLabel: {
              show: false,
              color: '#ffffff',
              fontSize: 14
            }
          }
        ],
        series: [
          {
            realtimeSort: true,
            type: 'bar',
            // barWidth: 20,
            encode: {
              x: 'value',
              y: 'name'
            },
            itemStyle: {
              normal: {
                color() {
                  return '#' + Math.floor(Math.random() * (256 * 256 * 256 - 1)).toString(16);
                }
              }
            },
            label: {
              show: true,
              position: 'right',
              fontSize: 10,
              color: '#ffffff'
            }
          }
        ]
      };
      myChart.setOption(option);
    };
    /*
     * const initMyCharts1 = () => {
     *   const myChartDom = document.getElementById('myChartDom1');
     *   const myChart = echarts.init(myChartDom);
     *   const seriesData = data.subHealthData.seriesData;
     *   const legendData = data.subHealthData.legendData;
     *   const xAxisData = data.subHealthData.xAxisData;
     *   const series = [];
     *   legendData.map((disease) => {
     *     series.push({
     *       name: disease,
     *       type: 'bar',
     *       label: labelOption,
     *       emphasis: {
     *         focus: 'series'
     *       },
     *       data: seriesData[disease]
     *     });
     *   });
     *   const option = {
     *     title: {
     *       text: '亚健康状况（按疾病种类）',
     *       x: 'center',
     *       textStyle: {
     *         color: '#ffffff',
     *         fontSize: 14
     *       }
     *     },
     *     tooltip: {
     *       trigger: 'axis',
     *       axisPointer: {
     *         type: 'shadow'
     *       }
     *     },
     *     legend: {
     *       top: '8%',
     *       itemWidth: 15,
     *       itemHeight: 15,
     *       data: legendData,
     *       textStyle: {
     *         color: '#ffffff',
     *         fontSize: 12
     *       }
     *     },
     *     xAxis: [
     *       {
     *         type: 'category',
     *         axisTick: {show: false},
     *         axisLabel: {
     *           interval: 0,
     *           rotate: 0,
     *           color: '#ffffff',
     *           fontSize: 12,
     *           formatter(value) {
     *             if (value.length > 6) {
     *               return value.slice(0, 5) + '...';
     *             } else {
     *               return value;
     *             }
     *           }
     *         },
     *         data: xAxisData
     *       }
     *     ],
     *     yAxis: [
     *       {
     *         type: 'value',
     *         interval: 100,
     *         splitLine: {
     *           show: false
     *         },
     *         axisLabel: {
     *           show: false,
     *           color: '#ffffff',
     *           fontSize: 14
     *         }
     *       }
     *     ],
     *     series
     *   };
     *   myChart.setOption(option);
     * };
     */
    const initAgeHealthCharts = () => {
      let i = 0;
      data.ageList.map(item => {
        i++;
        if (data.ageHealthDataList[item] && data.ageHealthDataList[item].length > 0) {
          const myChartDom = document.getElementById('chartAge' + i);
          const myChart = echarts.init(myChartDom);
          const option = {
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'shadow'
              }
            },
            grid: {
              left: '2%',
              right: '2%',
              top: '0%'
            },
            yAxis: [
              {
                axisLabel: {
                  inside: true,
                  fontSize: 12,
                  color: '#ffffff'
                },
                axisTick: {
                  show: false
                },
                axisLine: {
                  show: false
                },
                z: 10,
                data: data.ageHealthDataList[item].map(item => {
                  return item.name.replace('\n', '') + '（' + item.value + '人）';
                })
              }
            ],
            xAxis: [
              {
                type: 'value',
                interval: 50,
                splitLine: {
                  show: false
                },
                axisLabel: {
                  show: false,
                  color: '#ffffff',
                  fontSize: 14
                }
              }
            ],
            series: [
              {
                type: 'bar',
                data: data.ageHealthDataList[item],
                itemStyle: {
                  color: new echarts.graphic.LinearGradient(1, 0, 0, 0, [
                    {offset: 0, color: '#fee26f'},
                    {offset: 0.5, color: '#f4aa40'},
                    {offset: 1, color: '#e86406'}
                  ])
                },
                label: {
                  show: false,
                  position: 'insideRight',
                  fontSize: 10,
                  color: '#ffffff'
                }
              }
            ]
          };
          myChart.setOption(option);
        }
      });
    };
    const initAgeSubHealthCharts = () => {
      let i = 0;
      data.ageList.map(item => {
        i++;
        if (data.ageSubHealthDataList[item] && data.ageSubHealthDataList[item].length > 0) {
          const myChartDom = document.getElementById('chartAgeSub' + i);
          const myChart = echarts.init(myChartDom);
          const option = {
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'shadow'
              }
            },
            grid: {
              left: '2%',
              right: '2%',
              top: '0%'
            },
            yAxis: [
              {
                axisLabel: {
                  inside: true,
                  fontSize: 12,
                  width: '300px',
                  color: '#ffffff'
                },
                axisTick: {
                  show: false
                },
                axisLine: {
                  show: false
                },
                z: 10,
                data: data.ageSubHealthDataList[item].map(item => {
                  return item.name.replace('\n', '') + '（' + item.value + '人）';
                })
              }
            ],
            xAxis: [
              {
                type: 'value',
                interval: 1,
                splitLine: {
                  show: false
                },
                axisLabel: {
                  show: false,
                  color: '#ffffff',
                  fontSize: 14
                }
              }
            ],
            series: [
              {
                type: 'bar',
                data: data.ageSubHealthDataList[item],
                itemStyle: {
                  color: new echarts.graphic.LinearGradient(1, 0, 0, 0, [
                    {offset: 0, color: '#fee26f'},
                    {offset: 0.5, color: '#f4aa40'},
                    {offset: 1, color: '#e86406'}
                  ])
                }
              }
            ]
          };
          myChart.setOption(option);
        }
      });
    };
    const initUnitCharts = (index, chartUnit) => {
      const myUnitChart = echarts.init(chartUnit.value);
      const option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        dataset: [
          {
            /*
             * 这里指定了维度名的顺序，从而可以利用默认的维度到坐标轴的映射。
             * 如果不指定 dimensions，也可以通过指定 series.encode 完成映射，参见后文。
             */
            // dimensions: ['convertNum', 'conveyingNum', 'id', 'name', 'opType', 'successNum', 'type'],
            source: data.unitList[index - 1]
          }
        ],
        grid: {
          left: '40%',
          right: '10%',
          top: '0%'
        },
        yAxis: [
          {
            axisLabel: {
              fontSize: 12,
              color: '#ffffff'
            },
            axisTick: {
              show: false
            },
            axisLine: {
              show: true
            },
            z: 10,
            type: 'category',
            inverse: true
          }
        ],
        xAxis: [
          {
            type: 'value',
            splitLine: {
              show: false
            },
            axisLabel: {
              show: false,
              color: '#ffffff',
              fontSize: 14
            }
          }
        ],
        series: [
          {
            realtimeSort: true,
            type: 'bar',
            barWidth: 10,
            encode: data.encodeList[index - 1],
            itemStyle: {
              normal: {
                color() {
                  return '#' + Math.floor(Math.random() * (256 * 256 * 256 - 1)).toString(16);
                }
              }
            },
            label: {
              show: true,
              position: 'right',
              fontSize: 10,
              color: '#ffffff'
            }
          }
        ]
      };
      myUnitChart.setOption(option);
    };
    const initOtherChart = (data, chartOther) => {
      // const myChartDom = document.getElementById(id);
      const myOtherChart = echarts.init(chartOther.value);
      const option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        dataset: [
          {
            /*
             * 这里指定了维度名的顺序，从而可以利用默认的维度到坐标轴的映射。
             * 如果不指定 dimensions，也可以通过指定 series.encode 完成映射，参见后文。
             */
            // dimensions: ['convertNum', 'conveyingNum', 'id', 'name', 'opType', 'successNum', 'type'],
            source: data
          }
        ],
        grid: {
          left: '20%',
          right: '10%',
          top: '0%',
          bottom: '0%'
        },
        yAxis: [
          {
            axisLabel: {
              fontSize: 12,
              color: '#ffffff'
            },
            axisTick: {
              show: false
            },
            axisLine: {
              show: true
            },
            z: 10,
            type: 'category',
            inverse: true
          }
        ],
        xAxis: [
          {
            type: 'value',
            splitLine: {
              show: false
            },
            axisLabel: {
              show: false,
              color: '#ffffff',
              fontSize: 14
            }
          }
        ],
        series: [
          {
            realtimeSort: true,
            type: 'bar',
            barWidth: 10,
            encode: {
              x: 'num',
              y: 'name'
            },
            itemStyle: {
              normal: {
                color() {
                  return '#' + Math.floor(Math.random() * (256 * 256 * 256 - 1)).toString(16);
                }
              }
            },
            label: {
              show: false,
              position: 'right',
              fontSize: 10,
              color: '#ffffff'
            }
          }
        ]
      };
      myOtherChart.setOption(option);
    };
    onMounted(() => {
      queryData();
    });
    const onFullscreenClick = () => {
      // 判断是否支持
      if (screenfull.isEnabled) {
        if (data.isFull) {
          data.isFull = false;
        } else {
          data.isFull = true;
        }
        const fullDiv = document.getElementById('fullDiv');
        screenfull.toggle(fullDiv);
      } else {
        message.error('不支持全屏');
        return false;
      }
    };
    const onTabChange = key => {
      data.activeKey = key;
      clearInterval(data.timerId);
      data.timerId = setInterval(changeTab, 10000);
    };
    const changeTab = () => {
      if (data.activeKey === '5') {
        data.activeKey = '1';
      } else {
        const i = parseInt(data.activeKey, 10);
        data.activeKey = '' + (i + 1);
      }
    };
    return {
      ...toRefs(data),
      chartOther1,
      chartOther2,
      chartOther3,
      chartUnit1,
      chartUnit2,
      chartUnit3,
      chartUnit4,
      fullDiv,
      onTabChange,
      onFullscreenClick
    };
  }
});
</script>

<style lang="scss" scoped>
  @import '@/style/chart/index.scss';
</style>

<template>
  <div class="main">
    <div class="header"></div>
    <div class="content">
      <a-table
        :columns="columns"
        :row-key="record => record.id"
        :data-source="dataSource"
        :pagination="pagination"
        :loading="loading"
        :locale="locale"
        :scroll="{ y: '75vh' }"
        @change="handleTableChange"
        :bordered="true"
      >
        <template
          #customFilterDropdown="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }"
        >
          <div style="padding: 8px">
            <a-input
              ref="searchInput"
              :placeholder="`搜索 ${column.title}`"
              :value="selectedKeys[0]"
              style="width: 128px; margin-bottom: 8px; display: block"
              @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
              @pressEnter="handleSearch(selectedKeys, confirm, column.dataIndex)"
            />
            <a-button size="small" style="width: 60px; margin-right: 8px" @click="handleReset(clearFilters)">
              重置
            </a-button>
            <a-button
              type="primary"
              size="small"
              style="width: 60px;"
              @click="handleSearch(selectedKeys, confirm, column.dataIndex)"
            >
              <template #icon>
                <SearchOutlined/>
              </template>
              搜索
            </a-button>

          </div>
        </template>
        <template #customFilterIcon="{ filtered }">
          <search-outlined :style="{ fontSize:'15px',color: filtered ? '#108ee9' : undefined }"/>
        </template>
        <template #bodyCell="{ column, record }">
          <template v-if="column.dataIndex === 'dataSources'">
            <div>
              {{ dataSourcesStr[record.dataSources-1]}}
            </div>
          </template>
          <template v-if="column.dataIndex === 'operation'">
            <a v-if="record.dataSources>3" @click="showReport(record)">查看</a>
          </template>
        </template>
      </a-table>
    </div>
  </div>
</template>

<script>
import {defineComponent, onMounted, reactive, toRefs, ref} from 'vue';
import {useStore} from 'vuex';
import {SearchOutlined} from '@ant-design/icons-vue';

export default defineComponent({
  name: 'EcgData',
  components: {
    SearchOutlined
  },
  setup() {
    const store = useStore();
    const searchInput = ref();
    const columns = [
      {
        title: '姓名',
        dataIndex: 'name',
        width: '100px',
        customFilterDropdown: true,
        onFilter: (value, record) => record.name.toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
          if (visible) {
            setTimeout(() => {
              searchInput.value.focus();
            }, 100);
          }
        }
      }, {
        title: '手机号',
        dataIndex: 'phone',
        width: '150px',
        customFilterDropdown: true,
        onFilter: (value, record) => {
          if (record.phone) {
            return record.phone.toString().toLowerCase().includes(value.toLowerCase());
          } else {
            return false;
          }
        },
        onFilterDropdownVisibleChange: visible => {
          if (visible) {
            setTimeout(() => {
              searchInput.value.focus();
            }, 100);
          }
        }
      }, {
        title: '身份证号',
        dataIndex: 'idCard',
        width: '200px',
        customFilterDropdown: true,
        onFilter: (value, record) => {
          if (record.idCard) {
            return record.idCard.toString().toLowerCase().includes(value.toLowerCase());
          } else {
            return false;
          }
        },
        onFilterDropdownVisibleChange: visible => {
          if (visible) {
            setTimeout(() => {
              searchInput.value.focus();
            }, 100);
          }
        }
      }, {
        title: '检查日期',
        dataIndex: 'takeTime',
        width: '200px'
      }, {
        title: '数据来源',
        dataIndex: 'dataSources',
        filters: [
          {
            text: '好络维心电记录仪心电图检测',
            value: 1
          },
          {
            text: '好络维动态心电图记录仪心电监测',
            value: 2
          },
          {
            text: '好络维表带心电图检测',
            value: 3
          },
          {
            text: '卡帕奇手持心电卡常规心电图检测',
            value: 4
          },
          {
            text: '卡帕奇手持心电卡综合健康检测',
            value: 5
          },
          {
            text: '卡帕奇超长程心电贴心电监测',
            value: 6
          },
          {
            text: '乐普心电图机心电图监测',
            value: 7
          },
          {
            text: '乐普健康一体机健康检测',
            value: 8
          },
          {
            text: '康旭AI健康协助检测',
            value: 9
          }
        ],
        width: '200px'
      }, {
        title: '心率',
        dataIndex: 'heartRate',
        width: '100px'
      }, {
        title: '操作',
        dataIndex: 'operation',
        width: '100px'

      }
    ];
    const data = reactive({
      locale: {
        filterConfirm: '确定',
        filterReset: '重置',
        emptyText: '暂无数据'
      },
      columns,
      dataSourcesStr: [
        '好络维心电记录仪心电图检测', '好络维动态心电图记录仪心电监测', '好络维表带心电图检测',
        '卡帕奇手持心电卡常规心电图检测', '卡帕奇手持心电卡综合健康检测', '卡帕奇超长程心电贴心电监测',
        '乐普心电图机心电图监测', '乐普健康一体机健康检测', '康旭AI健康协助检测'
      ],
      loading: false,
      pagination: {
        total: 0,
        pageSize: 10,
        page: 1,
        showSizeChanger: true
      },
      dataSource: []
    });
    const queryData = params => {
      store.dispatch('health/queryEcgData', params).then(res => {
        data.loading = false;
        if (res && res.data) {
          data.pagination.total = res.data.data.total;
          if (data.pagination.total > 0) {
            data.dataSource.push(...res.data.data.list);
          }
        }
      });
    };
    const handleTableChange = (pag, filters, sorter) => {
      data.dataSource = [];
      data.loading = true;
      data.pagination.page = pag?.current;
      data.pagination.pageSize = pag?.pageSize;
      data.pagination.total = 0;
      queryData({
        pageSize: pag.pageSize,
        page: pag?.current,
        sortField: sorter.field,
        sortOrder: sorter.order,
        ...filters
      });
    };
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
      confirm();
    };
    const handleReset = clearFilters => {
      clearFilters({
        confirm: true
      });
      // state.searchText = '';
    };
    const showReport = record => {
      const dataType = record.dataType; const dataSources = parseInt(record.dataSources, 10);
      switch (dataType) {
        case 1:
          switch (dataSources) {
            case 1:
            case 2:
            case 3:
            default:
              // that.openHlwReport(report.reportNo);
              break;
            case 4:
              window.open('https://app.liferichin.com/ecg/report/detail?type=' + 0 + '&id=' + record.reportNo);
              break;
            case 5:
              window.open('https://app.liferichin.com/ecg/report/detail?type=' + 1 + '&id=' + record.reportNo);
              break;
          }
          break;
        case 2:
          window.open(record.pdfPath);
          break;
        case 3:
        default:
          window.open(record.urlPath);
          break;
      }
    };

    onMounted(() => {
      queryData({
        pageSize: data.pagination.pageSize,
        page: data.pagination.page
      });
    });
    return {
      ...toRefs(data),
      searchInput,
      showReport,
      handleTableChange,
      handleSearch,
      handleReset

    };
  }
});
</script>

<style lang="scss" scoped>
  @import '@/style/health/ecgData.scss';
</style>

import {createStore} from 'vuex';
import user from './modules/user';
import personel from './modules/personel';
import health from './modules/health';
import info from './modules/info';
import ecg from './modules/ecg';
import system from './modules/system';
import chart from './modules/chart';
import his from './modules/his';
import createPersistedState from 'vuex-persistedstate';

export default createStore({
  plugins: [
    createPersistedState({
      reducer(val) {
        return {
          // 只储存state中的assessmentData
          user: val.user
        };
      }
    })
  ],
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    user,
    personel,
    health,
    info,
    ecg,
    system,
    chart,
    his
  }
});

<template>
  <div class="main">
    <div class="header"></div>
    <div class="content">
      <a-table
        :columns="columns"
        :row-key="record => record.id"
        :data-source="dataSource"
        :pagination="pagination"
        :loading="loading"
        :locale="locale"
        :scroll="{ y: '75vh' }"
        @change="handleTableChange"
        :bordered="true"
      >
        <template
          #customFilterDropdown="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }"
        >
          <div style="padding: 8px">
            <a-input
              ref="searchInput"
              :placeholder="`搜索 ${column.title}`"
              :value="selectedKeys[0]"
              style="width: 128px; margin-bottom: 8px; display: block"
              @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
              @pressEnter="handleSearch(selectedKeys, confirm, column.dataIndex)"
            />
            <a-button size="small" style="width: 60px; margin-right: 8px" @click="handleReset(clearFilters)">
              重置
            </a-button>
            <a-button
              type="primary"
              size="small"
              style="width: 60px;"
              @click="handleSearch(selectedKeys, confirm, column.dataIndex)"
            >
              <template #icon>
                <SearchOutlined/>
              </template>
              搜索
            </a-button>

          </div>
        </template>
        <template #customFilterIcon="{ filtered }">
          <search-outlined :style="{ fontSize:'15px',color: filtered ? '#108ee9' : undefined }"/>
        </template>
        <template #bodyCell="{ column, record }">
          <template v-if="column.dataIndex === 'filePath'">
            <a-image :width="50" :height="50" :src="record.filePath"
                     style="object-fit: fill;height:50px;cursor: pointer;"/>
            <!--<a v-if="record.img!=''" @click="showImg(record.img)">查看</a>-->
          </template>
          <template v-if="column.dataIndex === 'state'">
            <div style="color: #f5ae19;" v-if="record.state==1">未处理</div>
            <div style="color: #05f53b;" v-if="record.state==2">已处理</div>
          </template>
          <template v-if="column.dataIndex === 'operation'">
            <a v-if="record.state==1" @click="handleStete(record.id)">处理</a>
          </template>
        </template>
      </a-table>
    </div>
  </div>
</template>

<script>
import {defineComponent, onMounted, reactive, toRefs, ref, createVNode} from 'vue';
import {useStore} from 'vuex';
import {SearchOutlined, ExclamationCircleOutlined} from '@ant-design/icons-vue';
import {Modal, message} from 'ant-design-vue';

export default defineComponent({
  name: 'Appeal',
  components: {
    SearchOutlined
  },
  setup() {
    const store = useStore();
    const searchInput = ref();
    const columns = [
      {
        title: '姓名',
        dataIndex: 'name',
        width: '100px',
        customFilterDropdown: true,
        onFilter: (value, record) => record.name.toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
          if (visible) {
            setTimeout(() => {
              searchInput.value.focus();
            }, 100);
          }
        }
      }, {
        title: '身份证号',
        dataIndex: 'idCard',
        width: '200px',
        customFilterDropdown: true,
        onFilter: (value, record) => {
          if (record.idCard) {
            return record.idCard.toString().toLowerCase().includes(value.toLowerCase());
          } else {
            return false;
          }
        },
        onFilterDropdownVisibleChange: visible => {
          if (visible) {
            setTimeout(() => {
              searchInput.value.focus();
            }, 100);
          }
        }
      }, {
        title: '联系方式',
        dataIndex: 'phone',
        width: '150px',
        customFilterDropdown: true,
        onFilter: (value, record) => {
          if (record.phone) {
            return record.phone.toString().toLowerCase().includes(value.toLowerCase());
          } else {
            return false;
          }
        },
        onFilterDropdownVisibleChange: visible => {
          if (visible) {
            setTimeout(() => {
              searchInput.value.focus();
            }, 100);
          }
        }
      }, {
        title: '日期',
        dataIndex: 'date',
        width: '200px'
      }, {
        title: '图片',
        dataIndex: 'filePath',
        width: '100px'

      }, {
        title: '状态',
        dataIndex: 'state',
        width: '100px',
        filters: [
          {
            text: '未处理',
            value: '1'
          }, {
            text: '已处理',
            value: '2'
          }
        ]
      }, {
        title: '操作',
        dataIndex: 'operation',
        width: '100px'

      }
    ];
    const data = reactive({
      locale: {
        filterConfirm: '确定',
        filterReset: '重置',
        emptyText: '暂无数据'
      },
      columns,
      loading: false,
      pagination: {
        total: 0,
        pageSize: 10,
        page: 1,
        showSizeChanger: true
      },
      dataSource: []
    });
    const queryData = params => {
      store.dispatch('info/queryAppealData', params).then(res => {
        data.loading = false;
        if (res && res.data) {
          data.pagination.total = res.data.data.total;
          if (data.pagination.total > 0) {
            data.dataSource.push(...res.data.data.list);
          }
        }
      });
    };
    const handleTableChange = (pag, filters, sorter) => {
      data.dataSource = [];
      data.loading = true;
      data.pagination.page = pag?.current;
      data.pagination.pageSize = pag?.pageSize;
      data.pagination.total = 0;
      queryData({
        pageSize: pag.pageSize,
        page: pag?.current,
        sortField: sorter.field,
        sortOrder: sorter.order,
        ...filters
      });
    };
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
      confirm();
    };
    const handleReset = clearFilters => {
      clearFilters({
        confirm: true
      });
      // state.searchText = '';
    };
    const updateState = params => {
      store.dispatch('info/updateAppealState', params).then(res => {
        if (res && res.data) {
          const success = res.data.success;
          if (success) {
            data.dataSource = [];
            data.loading = true;
            data.pagination.page = 1;
            queryData({
              pageSize: data.pagination.pageSize,
              page: data.pagination.page
            });
          } else {
            message.error('处理失败');
          }
        } else {
          message.error('处理失败');
        }
      });
    };
    const handleStete = id => {
      Modal.confirm({
        title: '更改状态为已处理',
        icon: createVNode(ExclamationCircleOutlined),
        content: '',
        okText: '确认',
        okType: 'primary',
        cancelText: '取消',
        onOk() {
          updateState({id, state: 2});
        },
        onCancel() {
          // console.log('Cancel');
        }
      });
    };
    onMounted(() => {
      data.loading = true;
      queryData({
        pageSize: data.pagination.pageSize,
        page: data.pagination.page
      });
    });
    return {
      ...toRefs(data),
      searchInput,
      handleTableChange,
      handleSearch,
      handleReset,
      handleStete
    };
  }
});
</script>

<style lang="scss" scoped>
  @import '@/style/info/appeal.scss';
</style>

import api from '../../api';

const state = () => ({
  authorization: '',
  title: '',
  userName: '',
  userInfo: null
});

// getters
const getters = {};

// actions
const actions = {
  doLogin(context, payload) {
    const {username, password} = payload;
    return new Promise((resolve, reject) => {
      api.cmsApi.login(username, password).then(res => {
        if (res && res.data) {
          context.commit('setAuthorization', {authorization: res.data.token_type + ' ' + res.data.access_token, username});
          resolve();
        } else {
          reject(new Error(''));
        }
      });
    });
  },
  async doLoginByToken(context, payload) {
    const {token} = payload;
    context.commit('clearAuthorization');
    const res = await api.cmsApi.loginByToken(token);
    let success = false;
    if (res && res.data) {
      success = res.data.success;
      if (success) {
        const username = res.data.data.userName;
        const authorization = res.data.data.authorization;
        context.commit('setAuthorization', {
          authorization: authorization.token_type + ' ' + authorization.access_token,
          username
        });
      }
    }
    return {success};
  },
  queryModule(context, payload) {
    return new Promise((resolve, reject) => {
      api.cmsApi.queryModule().then(res => {
        if (res && res.data) {
          resolve(res.data);
        } else {
          reject(new Error(''));
        }
      });
    });
  },
  queryUserList(context, params) {
    return new Promise((resolve, reject) => {
      api.cmsApi.queryUserList(params).then(res => {
        resolve(res);
      });
    });
  },
  submitUserInfo(context, params) {
    return new Promise((resolve, reject) => {
      api.cmsApi.submitUserInfo(params).then(res => {
        resolve(res);
      });
    });
  },
  queryModuleTreeData(context, params) {
    return new Promise((resolve, reject) => {
      api.cmsApi.queryModuleTreeData(params).then(res => {
        if (res && res.data) {
          resolve(res.data);
        } else {
          reject(new Error(''));
        }
      });
    });
  },
  submitAuth(context, params) {
    return new Promise((resolve, reject) => {
      api.cmsApi.submitAuth(params).then(res => {
        resolve(res);
      });
    });
  },
  updateUserPass(context, params) {
    return new Promise((resolve, reject) => {
      api.cmsApi.updateUserPass(params).then(res => {
        resolve(res);
      });
    });
  },
  exit(context) {
    return new Promise((resolve, reject) => {
      context.commit('setAuthorization', '', '');
      resolve();
    });
  }
};

// mutations
const mutations = {
  setAuthorization(state, data) {
    const {authorization, username} = data;
    state.authorization = authorization;
    state.userName = username;
  },
  clearAuthorization(state) {
    state.authorization = '';
    state.userName = '';
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

import api from '../../api';

const state = () => ({
  authorization: '',
  title: '',
  userInfo: null
});

// getters
const getters = {};

// actions
const actions = {
  queryData(context, params) {
    return new Promise((resolve, reject) => {
      api.cmsApi.queryPersonelData(params).then(res => {
        resolve(res);
      });
    });
  },
  queryMemberData(context, params) {
    return new Promise((resolve, reject) => {
      api.cmsApi.queryPersonelMemberData(params).then(res => {
        resolve(res);
      });
    });
  },
  deleteMemberData(context, params) {
    return new Promise((resolve, reject) => {
      api.cmsApi.deletePersonelMemberData(params).then(res => {
        resolve(res);
      });
    });
  }
};

// mutations
const mutations = {
  setAuthorization(state, authorization) {
    state.authorization = authorization;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

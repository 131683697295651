<template>
  <div class="main">
    <form id="myForm" :action="url" method="post" target="iframe">
      <input type="hidden" name="userId" :value="userId"/>
      <input type="hidden" name="examRecordId" :value="examRecordId"/>
    </form>
    <iframe name="iframe" id="printIframe" width="100%" height="100%" frameborder="0" scrolling="auto"/>
  </div>
</template>

<script>
import {defineComponent, onMounted, reactive, toRefs} from 'vue';
import {useRoute} from 'vue-router';

export default defineComponent({
  name: 'YjReportDetail',
  setup(props) {
    const route = useRoute();
    const userId = route.params.userId;
    const examRecordId = route.params.examRecordId;

    const data = reactive({
      url: '/huiyitec/wechat/BingHuaInterface',
      // url: 'http://huiyitecwechat.huiyitec.cn/wechat/BingHuaInterface',
      userId,
      examRecordId
    });
    onMounted(() => {
      const from = document.getElementById('myForm');
      from.submit();
    });
    return {
      ...toRefs(data)
    };
  }
});
</script>

<style lang="scss" scoped>
  @import '@/style/common/YjReportDetailForWx.scss';
</style>

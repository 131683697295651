<template>
  <div class="main">
    <div class="header">
      <a-button type="primary" @click="onAdd">新增</a-button>
    </div>
    <div class="content">
      <a-table
        :columns="columns"
        :row-key="record => record.id"
        :data-source="dataSource"
        :pagination="pagination"
        :loading="loading"
        :locale="locale"
        :scroll="{ y: '75vh'}"
        @change="handleTableChange"
        :bordered="true"
      >
        <template
          #customFilterDropdown="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }"
        >
          <div style="padding: 8px">
            <a-input
              ref="searchInput"
              :placeholder="`搜索 ${column.title}`"
              :value="selectedKeys[0]"
              style="width: 128px; margin-bottom: 8px; display: block"
              @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
              @pressEnter="handleSearch(selectedKeys, confirm, column.dataIndex)"
            />
            <a-button size="small" style="width: 60px; margin-right: 8px" @click="handleReset(clearFilters)">
              重置
            </a-button>
            <a-button
              type="primary"
              size="small"
              style="width: 60px;"
              @click="handleSearch(selectedKeys, confirm, column.dataIndex)"
            >
              <template #icon>
                <SearchOutlined/>
              </template>
              搜索
            </a-button>

          </div>
        </template>
        <template #customFilterIcon="{ filtered }">
          <search-outlined :style="{ fontSize:'15px',color: filtered ? '#108ee9' : undefined }"/>
        </template>
        <template #bodyCell="{ column, record }">
          <template v-if="column.dataIndex === 'type'">
            <div v-if="record.type=='1'">体检中心</div>
            <div v-if="record.type=='2'">医院</div>
            <div v-if="record.type=='3'">企业</div>
            <div v-if="record.type=='4'">专家</div>
          </template>
          <template v-if="column.dataIndex === 'conveyingNum'">
            {{ record.conveyingNum+'次' }}
          </template>
          <template v-if="column.dataIndex === 'convertNum'">
            {{ record.convertNum+'次' }}
          </template>
          <template v-if="column.dataIndex === 'successNum'">
            {{ record.successNum+'次' }}
          </template>
          <template v-if="column.dataIndex === 'operation'">
            <a @click="onEdit(record)">编辑</a>
            <a style="padding-left: 10px;" @click="deleteUnit(record.id)">删除</a>
          </template>
        </template>
      </a-table>
    </div>
  </div>
  <a-modal v-model:visible="isShowAdd" :title="formData.title" :footer="null">
    <a-form
      ref="formRef"
      name="custom-validation"
      :model="formData"
      :rules="rules"
      v-bind="layout"
      @finish="handleFinish"
    >
      <div class="form-item">
        <a-form-item has-feedback label="名称" name="name">
          <a-input v-model:value="formData.name" autocomplete="off"/>
        </a-form-item>
        <a-form-item label="类型" name="type">
          <a-select
            ref="select"
            v-model:value="formData.type"
            @change="handleTypeChange"
          >
            <a-select-option value="1">体检中心</a-select-option>
            <a-select-option value="2">医院</a-select-option>
            <a-select-option value="3">企业</a-select-option>
            <a-select-option value="4">专家</a-select-option>
          </a-select>
        </a-form-item>
      </div>
      <div class="form-item">
        <a-form-item has-feedback label="输送量" name="conveyingNum">
          <a-input-number :min="0" addon-after="次" v-model:value="formData.conveyingNum"/>
        </a-form-item>
        <a-form-item has-feedback label="转换量" name="convertNum">
          <a-input-number :min="0" addon-after="次" v-model:value="formData.convertNum"/>
        </a-form-item>
      </div>
      <div class="form-item">
        <a-form-item has-feedback label="服务量" name="successNum">
          <a-input-number :min="0" addon-after="次" v-model:value="formData.successNum"/>
        </a-form-item>
      </div>
      <div class="form-btns">
        <a-button type="primary" html-type="submit">提交</a-button>
        <a-button style="margin-left: 20px" @click="resetForm">重置</a-button>
      </div>
    </a-form>
  </a-modal>
</template>

<script>
import {defineComponent, onMounted, reactive, toRefs, ref, createVNode} from 'vue';
import {useStore} from 'vuex';
import {SearchOutlined, ExclamationCircleOutlined} from '@ant-design/icons-vue';
import {Modal, message} from 'ant-design-vue';

export default defineComponent({
  name: 'Unit',
  components: {
    SearchOutlined
  },
  setup() {
    const store = useStore();
    const searchInput = ref();
    const columns = [
      {
        title: '序号',
        dataIndex: 'id',
        width: '60px',
        sortDirections: ['descend', 'ascend'],
        showSorterTooltip: false,
        sorter: true,
        defaultSortOrder: 'descend'
      },
      {
        title: '名称',
        dataIndex: 'name',
        width: '100px',
        sorter: true,
        sortDirections: ['descend', 'ascend'],
        showSorterTooltip: false,
        customFilterDropdown: true,
        onFilter: (value, record) => record.name.toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
          if (visible) {
            setTimeout(() => {
              searchInput.value.focus();
            }, 100);
          }
        }
      }, {
        title: '类型',
        dataIndex: 'type',
        width: '150px',
        sorter: true,
        sortDirections: ['descend', 'ascend'],
        showSorterTooltip: false,
        filters: [
          {
            text: '体检中心',
            value: '1'
          }, {
            text: '医院',
            value: '2'
          }, {
            text: '企业',
            value: '3'
          }, {
            text: '专家',
            value: '4'
          }
        ]
      }, {
        title: '输送量',
        dataIndex: 'conveyingNum',
        width: '100px'
      }, {
        title: '转换量',
        dataIndex: 'convertNum',
        width: '100px'
      }, {
        title: '服务量',
        dataIndex: 'successNum',
        width: '100px'
      }, {
        title: '操作',
        dataIndex: 'operation',
        width: '100px'

      }
    ];
    const data = reactive({
      locale: {
        filterConfirm: '确定',
        filterReset: '重置',
        emptyText: '暂无数据'
      },
      columns,
      loading: false,
      pagination: {
        total: 0,
        pageSize: 10,
        page: 1,
        showSizeChanger: true
      },
      dataSource: [],
      isShowAdd: false,
      addTitle: '新增'
    });
    const queryData = params => {
      store.dispatch('chart/queryUnitData', params).then(res => {
        data.loading = false;
        if (res && res.data) {
          data.pagination.total = res.data.data.total;
          if (data.pagination.total > 0) {
            data.dataSource.push(...res.data.data.list);
          }
        }
      });
    };
    const handleTableChange = (pag, filters, sorter) => {
      data.dataSource = [];
      data.loading = true;
      data.pagination.page = pag?.current;
      data.pagination.pageSize = pag?.pageSize;
      data.pagination.total = 0;
      queryData({
        pageSize: pag.pageSize,
        page: pag?.current,
        sortField: sorter.field,
        sortOrder: sorter.order,
        ...filters
      });
    };
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
      confirm();
    };
    const handleReset = clearFilters => {
      clearFilters({
        confirm: true
      });
      // state.searchText = '';
    };
    const onEdit = record => {
      formData.title = '编辑';
      formData.opType = 'edit';
      formData.id = record.id;
      formData.name = record.name;
      formData.type = record.type + '';
      formData.conveyingNum = record.conveyingNum;
      formData.convertNum = record.convertNum;
      formData.successNum = record.successNum;
      data.isShowAdd = true;
    };
    const onAdd = () => {
      formData.title = '新增';
      formData.opType = 'add';
      formData.id = 0;
      formData.brand = '';
      formData.type = '1';
      formData.conveyingNum = 0;
      formData.convertNum = 0;
      formData.successNum = 0;
      data.isShowAdd = true;
    };
    onMounted(() => {
      queryData({
        pageSize: data.pagination.pageSize,
        page: data.pagination.page,
        sortField: 'id',
        sortOrder: 'descend'
      });
    });
    const formRef = ref();
    const formData = reactive({
      title: '新增',
      id: 0,
      opType: 'add',
      name: '',
      type: '0',
      conveyingNum: 0,
      convertNum: 0,
      successNum: 0
    });
    const rules = {
      name: [
        {
          required: true,
          message: '名称不能为空'
        }
      ]
    };
    const layout = {
      labelCol: {
        style: {
          width: '80px'
        }
      },
      wrapperCol: {
        span: 14
      }
    };
    const submitData = params => {
      store.dispatch('chart/submitUnit', params).then(res => {
        if (res && res.data && res.data.success) {
          data.dataSource = [];
          // data.pagination.page = 1;
          data.loading = true;
          queryData({
            pageSize: data.pagination.pageSize,
            page: data.pagination.page,
            sortField: 'id',
            sortOrder: 'descend'
          });
          data.isShowAdd = false;
          let msg = '新增成功';
          if (params.opType === 'edit') {
            msg = '编辑成功';
          }
          message.success(msg);
        } else {
          let msg = '新增失败,请重试';
          if (params.opType === 'edit') {
            msg = '编辑失败,请重试';
          }
          message.error(msg);
        }
      });
    };
    const handleFinish = values => {
      submitData(formData);
    };
    const resetForm = () => {
      formRef.value.resetFields();
    };
    const handleTypeChange = value => {
      formData.type = value;
    };
    const deleteData = params => {
      store.dispatch('chart/deleteUnitData', params).then(res => {
        if (res && res.data) {
          const success = res.data.success;
          if (success) {
            data.dataSource = [];
            data.loading = true;
            data.pagination.page = 1;
            queryData({
              pageSize: data.pagination.pageSize,
              page: data.pagination.page,
              sortField: 'id',
              sortOrder: 'descend'
            });
          } else {
            message.error('删除失败');
          }
        } else {
          message.error('删除失败');
        }
      });
    };
    const deleteUnit = id => {
      Modal.confirm({
        title: '是否删除数据',
        icon: createVNode(ExclamationCircleOutlined),
        content: '',
        okText: '确认',
        okType: 'primary',
        cancelText: '取消',
        onOk() {
          deleteData({id});
        },
        onCancel() {
          // console.log('Cancel');
        }
      });
    };
    return {
      ...toRefs(data),
      searchInput,
      handleTableChange,
      handleSearch,
      handleReset,
      onAdd,
      onEdit,
      deleteUnit,
      formData,
      formRef,
      rules,
      layout,
      handleFinish,
      resetForm,
      handleTypeChange
    };
  }
});
</script>

<style lang="scss" scoped>
  @import '@/style/chatData/unit.scss';
</style>

import api from '../../api';

const state = () => ({
});

// getters
const getters = {};

// actions
const actions = {
  queryHealthData(context, params) {
    return new Promise((resolve, reject) => {
      api.cmsApi.queryHealthData(params).then(res => {
        resolve(res);
      });
    });
  },
  queryYjReportData(context, params) {
    return new Promise((resolve, reject) => {
      api.cmsApi.queryYjReportData(params).then(res => {
        resolve(res);
      });
    });
  },
  queryEcgData(context, params) {
    return new Promise((resolve, reject) => {
      api.cmsApi.queryEcgData(params).then(res => {
        resolve(res);
      });
    });
  },
  queryMxjkData(context, params) {
    return new Promise((resolve, reject) => {
      api.cmsApi.queryMxjkData(params).then(res => {
        resolve(res);
      });
    });
  }

};

// mutations
const mutations = {
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

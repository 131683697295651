'use strict';
/* eslint-disable */

/**
 * @Author:jsen.xu
 */

/**
 * 获取URL参数
 * @param {参数名} label
 */
export function urlParam(label) {
  let url = {},
    lbl = label && label.toLowerCase()
  window.location.search.toLowerCase().slice(1).split('&').forEach(function (element, index) {
    let temp = element.split('=')
    url[temp[0]] = temp[1]
  })
  return decodeURIComponent(url[lbl] || '')
}

/**
 * 判断是否为空对象
 * @param {判断的对象} obj
 */
export function isEmptyObject(obj) {
  let name
  for (name in obj) {
    return false
  }
  return true
}

/**
 * 判断是否为空
 * @param {判断的对象} obj
 */
export function isNullOrEmpty(obj) {
  return undefined === obj || obj == null || obj === ''
}

/**
 * 验证手机号格式
 * @param {判断的对象} v
 */
export function validPhone(v) {
  return /^1[345789]\d{9}$/.test(v)
}

/**
 * 验证身份证
 * @param {*} v
 */
export function validID(v) {
  return /(^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$)|(^[1-9]\d{5}\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{2}$)/.test(v)
}
export function formatNumber(e) {
  return (e = Math.round(e).toString())[1] ? e : "0" + e;
}
export function formatDate(e) {
  let t = new Date(e),y=t.getFullYear(), m = t.getMonth() + 1, d = t.getDate();
  return [ y,m,d ].map(formatNumber).join("-");
}
/**
 * 获取当前日期的最小时刻
 */
export function getMinDayTime(date = new Date()) {
  let year = date.getFullYear(),
    month = date.getMonth(),
    day = date.getDate()

  return new Date(year, month, day, 0).getTime()
}

export function getAgeFromBirthday(e) {
  let t = void 0, r = (t = new Date(e)).getFullYear(), n = t.getMonth() + 1, a = t.getDate(), o = new Date(),
    u = o.getFullYear(), i = o.getMonth() + 1, g = o.getDate()
  if (u === r) return 0
  let m = u - r
  return m < 0 ? -1 : i === n ? g - a < 0 ? m - 1 : m : i - n < 0 ? m - 1 : m
}

export function idCardToObj(idCard) {
  let birth = (idCard.length === 18) ? idCard.slice(6, 14) : idCard.slice(6, 12);
  let order = (idCard.length === 18) ? idCard.slice(-2, -1) : idCard.slice(-1);
  let birthDay = (idCard.length === 18) ? ([birth.slice(0, 4), birth.slice(4, 6), birth.slice(-2)].join('-')) : (['19' + birth.slice(0, 2), birth.slice(2, 4), birth.slice(-2)].join('-'));
  let sex = (order % 2 === 0 ? 2 : 1);//1:男 2：女
  let age = getAgeFromBirthday(birthDay);
  return {birthDay, sex,age}
}

export function guid(){
  function S4() {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  }

  return (S4() + S4() + '-' + S4() + '-' + S4() + '-' + S4() + '-' + S4() + S4() + S4());
};
//获取cookie、
export function getCookie(name) {
  var arr, reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)");
  if (arr = document.cookie.match(reg))
    return (arr[2]);
  else
    return null;
}

//设置cookie,增加到vue实例方便全局调用
export function setCookie (c_name, value, expiredays,domain) {
  var exdate = new Date();
  exdate.setTime(exdate.getTime() + expiredays);
  document.cookie = c_name + "=" + escape(value) + ((expiredays == null) ? "" : ";expires=" + exdate.toUTCString())+ ((domain == null) ? "" : ";domain=" + domain);
};

//删除cookie
export function delCookie (name) {
  var exp = new Date();
  exp.setTime(exp.getTime() - 1);
  var cval = getCookie(name);
  if (cval != null)
    document.cookie = name + "=" + cval + ";expires=" + exp.toUTCString();
};
export function calDay (vipDate) {
  let dateSpan,
    iDays;
  let year = new Date().getFullYear(), month = new Date().getMonth(), day = new Date().getDate(), vipTime = new Date(vipDate), vip_year = vipTime.getFullYear(), vip_month = vipTime.getMonth(), vip_day = vipTime.getDate();
  let sDate1 = Date.parse(new Date(year, month, day));
  let sDate2 = Date.parse(new Date(vip_year, vip_month, vip_day));
  dateSpan = sDate2 - sDate1;
  // dateSpan = Math.abs(dateSpan);
  iDays = Math.floor(dateSpan / (24 * 3600 * 1000));
  return iDays
};

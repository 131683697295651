import {createRouter, createWebHistory} from 'vue-router';
import index from '../views/Index';
import login from '../views/Login';
import YjReportDetailForWx from '../views/common/YjReportDetailForWx';

const routes = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/index',
    name: 'index',
    component: index,
    meta: {
      title: '后台管理'
    }
  },
  {
    path: '/login',
    name: 'login',
    component: login,
    meta: {
      title: '后台管理'
    }
  },
  {
    path: '/YjReportDetail/:userId/:examRecordId',
    name: 'YjReportDetailForWx',
    component: YjReportDetailForWx,
    meta: {
      title: '报告'
    }
  }
];
/*
 * children: [
 *   {
 *     path: '',
 *     component: receiveUser
 *   },
 *   {
 *     path: 'receiveUser',
 *     component: receiveUser
 *   },
 *   {
 *     path: 'report',
 *     component: report
 *   }
 * ]
 */
const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;

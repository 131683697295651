<template>
  <div class="main">
    <div class="header">
      <a-button type="primary" @click="onAdd">新增</a-button>
    </div>
    <div class="content">
      <a-table
        :columns="columns"
        :row-key="record => record.dbid"
        :data-source="dataSource"
        :pagination="pagination"
        :loading="loading"
        :locale="locale"
        :scroll="{ y: '75vh',x: '80vw'  }"
        @change="handleTableChange"
        :bordered="true"
      >
        <template
          #customFilterDropdown="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }"
        >
          <div style="padding: 8px">
            <a-input
              ref="searchInput"
              :placeholder="`搜索 ${column.title}`"
              :value="selectedKeys[0]"
              style="width: 128px; margin-bottom: 8px; display: block"
              @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
              @pressEnter="handleSearch(selectedKeys, confirm, column.dataIndex)"
            />
            <a-button size="small" style="width: 60px; margin-right: 8px" @click="handleReset(clearFilters)">
              重置
            </a-button>
            <a-button
              type="primary"
              size="small"
              style="width: 60px;"
              @click="handleSearch(selectedKeys, confirm, column.dataIndex)"
            >
              <template #icon>
                <SearchOutlined/>
              </template>
              搜索
            </a-button>

          </div>
        </template>
        <template #customFilterIcon="{ filtered }">
          <search-outlined :style="{ fontSize:'15px',color: filtered ? '#108ee9' : undefined }"/>
        </template>
        <template #bodyCell="{ column, record }">
          <template v-if="column.dataIndex === 'operation'">
            <a @click="onEdit(record)">编辑</a>
            <a style="padding-left: 10px;" @click="deleteCost(record.id)">删除</a>
          </template>
        </template>
      </a-table>
    </div>
  </div>
  <a-modal v-model:visible="isShowAdd" :title="formState.title" :footer="null">
    <a-form
      ref="formRef"
      name="custom-validation"
      :model="formState"
      :rules="rules"
      v-bind="layout"
      @finish="handleFinish"
    >
      <div class="form-item">
        <a-form-item has-feedback label="设备编号" name="deviceId">
          <a-input v-model:value="formState.deviceId" autocomplete="off"/>
        </a-form-item>
      </div>
      <div class="form-item">
        <a-form-item has-feedback label="设备名称" name="deviceName">
          <a-input v-model:value="formState.deviceName" autocomplete="off"/>
        </a-form-item>
      </div>
      <div class="form-item">
        <a-form-item has-feedback label="设备型号" name="model">
          <a-input v-model:value="formState.model" autocomplete="off"/>
        </a-form-item>
      </div>

      <div class="form-btns">
        <a-button type="primary" html-type="submit">提交</a-button>
        <a-button style="margin-left: 20px" @click="resetForm">重置</a-button>
      </div>
    </a-form>
  </a-modal>
</template>

<script>
import {defineComponent, onMounted, reactive, toRefs, ref, createVNode} from 'vue';
import {useStore} from 'vuex';
import {SearchOutlined, ExclamationCircleOutlined} from '@ant-design/icons-vue';
import {Modal, message} from 'ant-design-vue';

export default defineComponent({
  name: 'Device',
  components: {
    SearchOutlined
  },
  setup() {
    const store = useStore();
    const searchInput = ref();
    const columns = [
      {
        title: '编号',
        dataIndex: 'id',
        width: '60px',
        sortDirections: ['descend', 'ascend'],
        showSorterTooltip: false,
        sorter: true,
        defaultSortOrder: 'descend'
      },
      {
        title: '设备编号',
        dataIndex: 'deviceId',
        width: '100px',
        sorter: true,
        sortDirections: ['descend', 'ascend'],
        showSorterTooltip: false,
        customFilterDropdown: true,
        onFilter: (value, record) => record.deviceId.toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
          if (visible) {
            setTimeout(() => {
              searchInput.value.focus();
            }, 100);
          }
        }
      }, {
        title: '设备名称',
        dataIndex: 'deviceName',
        width: '150px',
        sorter: true,
        sortDirections: ['descend', 'ascend'],
        showSorterTooltip: false,
        customFilterDropdown: true,
        onFilter: (value, record) => record.deviceName.toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
          if (visible) {
            setTimeout(() => {
              searchInput.value.focus();
            }, 100);
          }
        }
      }, {
        title: '设备型号',
        dataIndex: 'model',
        width: '150px',
        sorter: true,
        sortDirections: ['descend', 'ascend'],
        showSorterTooltip: false,
        customFilterDropdown: true,
        onFilter: (value, record) => record.model.toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
          if (visible) {
            setTimeout(() => {
              searchInput.value.focus();
            }, 100);
          }
        }
      }, {
        title: '操作',
        dataIndex: 'operation',
        width: '100px'

      }
    ];
    const data = reactive({
      locale: {
        filterConfirm: '确定',
        filterReset: '重置',
        emptyText: '暂无数据'
      },
      columns,
      loading: false,
      pagination: {
        total: 0,
        pageSize: 10,
        page: 1,
        showSizeChanger: true
      },
      dataSource: [],
      isShowAdd: false,
      addTitle: '新增'
    });
    const queryData = params => {
      store.dispatch('system/queryDeviceList', params).then(res => {
        data.loading = false;
        if (res && res.data) {
          data.pagination.total = res.data.data.total;
          if (data.pagination.total > 0) {
            data.dataSource.push(...res.data.data.list);
          }
        }
      });
    };
    const handleTableChange = (pag, filters, sorter) => {
      data.dataSource = [];
      data.loading = true;
      data.pagination.page = pag?.current;
      data.pagination.pageSize = pag?.pageSize;
      data.pagination.total = 0;
      queryData({
        pageSize: pag.pageSize,
        page: pag?.current,
        sortField: sorter.field,
        sortOrder: sorter.order,
        ...filters
      });
    };
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
      confirm();
    };
    const handleReset = clearFilters => {
      clearFilters({
        confirm: true
      });
      // state.searchText = '';
    };
    const onEdit = record => {
      formState.title = '编辑';
      formState.opType = 'edit';
      formState.id = record.id;
      formState.devideId = record.devideId;
      formState.deviceName = record.deviceName;
      formState.model = record.model;
      data.isShowAdd = true;
    };
    const onAdd = () => {
      formState.title = '新增';
      formState.opType = 'add';
      formState.id = 0;
      formState.devideId = '';
      formState.deviceName = '';
      formState.model = '';
      data.isShowAdd = true;
    };
    onMounted(() => {
      queryData({
        pageSize: data.pagination.pageSize,
        page: data.pagination.page,
        sortField: 'id',
        sortOrder: 'descend'
      });
    });
    const formRef = ref();
    const formState = reactive({
      title: '新增',
      id: 0,
      opType: 'add',
      deviceId: '',
      deviceName: '',
      model: ''
    });
    const rules = {
      deviceId: [
        {
          required: true,
          message: '编号不能为空'
        }
      ],
      deviceName: [
        {
          required: true,
          message: '名称不能为空'
        }
      ]
    };
    const layout = {
      labelCol: {
        style: {
          width: '80px'
        }
      },
      wrapperCol: {
        span: 14
      }
    };
    const submitData = params => {
      store.dispatch('system/submitDeviceInfo', params).then(res => {
        if (res && res.data && res.data.success) {
          data.dataSource = [];
          data.pagination.page = 1;
          data.loading = true;
          queryData({
            pageSize: data.pagination.pageSize,
            page: data.pagination.page,
            sortField: 'id',
            sortOrder: 'descend'
          });
          data.isShowAdd = false;
          let msg = '新增成功';
          if (params.opType === 'edit') {
            msg = '编辑成功';
          }
          message.success(msg);
        } else {
          let msg = '新增失败,请重试';
          if (params.opType === 'edit') {
            msg = '编辑失败,请重试';
          }
          message.error(msg);
        }
      });
    };
    const handleFinish = values => {
      submitData(formState);
    };
    const resetForm = () => {
      formRef.value.resetFields();
    };
    const deleteData = params => {
      store.dispatch('system/deleteDevice', params).then(res => {
        if (res && res.data) {
          const success = res.data.success;
          if (success) {
            data.dataSource = [];
            data.loading = true;
            data.pagination.page = 1;
            queryData({
              pageSize: data.pagination.pageSize,
              page: data.pagination.page,
              sortField: 'id',
              sortOrder: 'descend'
            });
          } else {
            message.error('删除失败');
          }
        } else {
          message.error('删除失败');
        }
      });
    };
    const deleteCost = id => {
      Modal.confirm({
        title: '是否删除数据',
        icon: createVNode(ExclamationCircleOutlined),
        content: '',
        okText: '确认',
        okType: 'primary',
        cancelText: '取消',
        onOk() {
          deleteData({id});
        },
        onCancel() {
          // console.log('Cancel');
        }
      });
    };
    return {
      ...toRefs(data),
      searchInput,
      handleTableChange,
      handleSearch,
      handleReset,
      onAdd,
      onEdit,
      deleteCost,
      formState,
      formRef,
      rules,
      layout,
      handleFinish,
      resetForm
    };
  }
});
</script>

<style lang="scss" scoped>
  @import '@/style/system/device.scss';
</style>

<template>
  <a-config-provider :locale="locale">
  <suspense>
    <router-view/>
  </suspense>
  </a-config-provider>
</template>
<script>
import {ref} from 'vue';
// import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN';
import zhCN from 'ant-design-vue/es/locale/zh_CN';
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
dayjs.locale('cn');

export default {
  name: 'App',
  setup() {
    const locale = ref(zhCN);
    return {
      locale
    };
  }
};
</script>

<style lang="scss">
  #app {
    font-family: $font-family;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: $backColor;
  }
</style>

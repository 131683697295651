<template>
  <div class="main">
    <div class="header"></div>
    <div class="content">
      <a-table
        :columns="columns"
        :row-key="record => record.id"
        :data-source="dataSource"
        :pagination="pagination"
        :loading="loading"
        :locale="locale"
        :scroll="{ y: '75vh' }"
        @change="handleTableChange"
        :bordered="true"
      >
        <template
          #customFilterDropdown="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }"
        >
          <div style="padding: 8px">
            <a-input
              ref="searchInput"
              :placeholder="`搜索 ${column.title}`"
              :value="selectedKeys[0]"
              style="width: 128px; margin-bottom: 8px; display: block"
              @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
              @pressEnter="handleSearch(selectedKeys, confirm, column.dataIndex)"
            />
            <a-button size="small" style="width: 60px; margin-right: 8px" @click="handleReset(clearFilters)">
              重置
            </a-button>
            <a-button
              type="primary"
              size="small"
              style="width: 60px;"
              @click="handleSearch(selectedKeys, confirm, column.dataIndex)"
            >
              <template #icon>
                <SearchOutlined/>
              </template>
              搜索
            </a-button>

          </div>
        </template>
        <template #customFilterIcon="{ filtered }">
          <search-outlined :style="{ fontSize:'15px',color: filtered ? '#108ee9' : undefined }"/>
        </template>
        <template #bodyCell="{ column, record }">
          <template v-if="column.dataIndex === 'gender'">
            <p>
              {{ record.gender==0?'男':'女' }}
            </p>
          </template>
          <template v-else-if="column.dataIndex === 'conditions'">
          <p>
          {{ record.smoke==1?'吸烟':'' }}
          {{ record.diabetes==1?'糖尿病':'' }}
          {{ record.heartDisease==1?'心脏病':'' }}
          {{ record.takeBloodPressureMedicine==1?'服降压药':'' }}
          </p>
          </template>
        </template>
      </a-table>
    </div>
  </div>
</template>

<script>
import {defineComponent, onMounted, reactive, toRefs, ref} from 'vue';
import {useStore} from 'vuex';
import {SearchOutlined} from '@ant-design/icons-vue';

export default defineComponent({
  name: 'Personnel',
  components: {
    SearchOutlined
  },
  setup() {
    const store = useStore();
    const searchInput = ref();
    const columns = [
      {
        title: 'ID',
        dataIndex: 'id',
        // sorter: true,
        width: '50px',
        defaultSortOrder: 'descend'
      },
      {
        title: '用户ID',
        dataIndex: 'userId',
        width: '150px'
      },
      {
        title: '姓名',
        dataIndex: 'name',
        width: '100px',
        customFilterDropdown: true,
        onFilter: (value, record) => record.name.toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
          if (visible) {
            setTimeout(() => {
              searchInput.value.focus();
            }, 100);
          }
        }
      }, {
        title: '手机号',
        dataIndex: 'mobile',
        width: '150px',
        customFilterDropdown: true,
        onFilter: (value, record) => {
          if (record.mobile) {
            return record.mobile.toString().toLowerCase().includes(value.toLowerCase());
          } else {
            return false;
          }
        },
        onFilterDropdownVisibleChange: visible => {
          if (visible) {
            setTimeout(() => {
              searchInput.value.focus();
            }, 100);
          }
        }
      }, {
        title: '身份证号',
        dataIndex: 'idCard',
        width: '200px',
        customFilterDropdown: true,
        onFilter: (value, record) => {
          if (record.idCard) {
            return record.idCard.toString().toLowerCase().includes(value.toLowerCase());
          } else {
            return false;
          }
        },
        onFilterDropdownVisibleChange: visible => {
          if (visible) {
            setTimeout(() => {
              searchInput.value.focus();
            }, 100);
          }
        }
      }, {
        title: '性别',
        dataIndex: 'gender',
        filters: [
          {
            text: '男',
            value: '0'
          }, {
            text: '女',
            value: '1'
          }
        ],
        width: '70px'
      }, {
        title: '出生日期',
        dataIndex: 'formattedBirthday',
        width: '100px'
      }, {
        title: '身高(cm)',
        dataIndex: 'height',
        width: '100px'
      }, {
        title: '体重(kg)',
        dataIndex: 'weight',
        width: '100px'
      }, {
        title: '生活情况',
        dataIndex: 'conditions',
        filters: [
          {
            text: '糖尿病',
            value: 'diabetes'
          },
          {
            text: '心脏病',
            value: 'heart_disease'
          },
          {
            text: '吸烟',
            value: 'smoke'
          },
          {
            text: '服降压药',
            value: 'take_blood_pressure_medicine'
          }
        ]
      }
    ];
    const data = reactive({
      locale: {
        filterConfirm: '确定',
        filterReset: '重置',
        emptyText: '暂无数据'
      },
      columns,
      loading: false,
      pagination: {
        total: 0,
        pageSize: 10,
        page: 1,
        showSizeChanger: true
      },
      dataSource: []
    });
    const queryData = params => {
      store.dispatch('personel/queryData', params).then(res => {
        data.loading = false;
        if (res && res.data) {
          data.pagination.total = res.data.data.total;
          if (data.pagination.total > 0) {
            data.dataSource.push(...res.data.data.list);
          }
        }
      });
    };
    const handleTableChange = (pag, filters, sorter) => {
      data.dataSource = [];
      data.loading = true;
      data.pagination.page = pag?.current;
      data.pagination.pageSize = pag?.pageSize;
      data.pagination.total = 0;
      queryData({
        pageSize: pag.pageSize,
        page: pag?.current,
        ...filters
      });
      /*
       * sortField: sorter.field,
       *   sortOrder: sorter.order,
       */
    };
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
      confirm();
    };
    const handleReset = clearFilters => {
      clearFilters({
        confirm: true
      });
      // state.searchText = '';
    };
    onMounted(() => {
      data.loading = true;
      queryData({
        pageSize: data.pagination.pageSize,
        page: data.pagination.page
      });
    });

    return {
      ...toRefs(data),
      searchInput,
      handleTableChange,
      handleSearch,
      handleReset
    };
  }
});
</script>

<style lang="scss" scoped>
  @import '@/style/personnel/personnel.scss';
</style>

import api from '../../api';

const state = () => ({
});

// getters
const getters = {};

// actions
const actions = {
  queryCostData(context, params) {
    return new Promise((resolve, reject) => {
      api.cmsApi.queryCostData(params).then(res => {
        resolve(res);
      });
    });
  },
  submitCost(context, params) {
    return new Promise((resolve, reject) => {
      api.cmsApi.submitCost(params).then(res => {
        resolve(res);
      });
    });
  },
  deleteCostData(context, params) {
    return new Promise((resolve, reject) => {
      api.cmsApi.deleteCostData(params).then(res => {
        resolve(res);
      });
    });
  },
  updateSurplusNum(context, params) {
    return new Promise((resolve, reject) => {
      api.cmsApi.updateSurplusNum(params).then(res => {
        resolve(res);
      });
    });
  },
  queryBillData(context, params) {
    return new Promise((resolve, reject) => {
      api.cmsApi.queryBillData(params).then(res => {
        resolve(res);
      });
    });
  },
  queryOrderData(context, params) {
    return new Promise((resolve, reject) => {
      api.cmsApi.queryOrderData(params).then(res => {
        resolve(res);
      });
    });
  }
};

// mutations
const mutations = {
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

/**
 * api接口的统一出口
 */
// 医智接口
import cmsApi from '@/api/cmsApi';
// 其他模块的接口……

// 导出接口
export default {
  cmsApi
  // ...
};

import api from '../../api';

const state = () => ({
});

// getters
const getters = {};

// actions
const actions = {
  queryHisDataUploadInfo(context, params) {
    return new Promise((resolve, reject) => {
      api.cmsApi.queryHisDataUploadInfo(params).then(res => {
        resolve(res);
      });
    });
  },
  doHisDataUpload(context, params) {
    return new Promise((resolve, reject) => {
      api.cmsApi.doHisDataUpload(params).then(res => {
        resolve(res);
      });
    });
  }
};

// mutations
const mutations = {
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

<template>
  <div class="main">
    <div class="header"></div>
    <div class="content">
      <a-table
        :columns="columns"
        :row-key="record => record.id"
        :data-source="dataSource"
        :pagination="pagination"
        :loading="loading"
        :locale="locale"
        :scroll="{ y: '75vh' }"
        @change="handleTableChange"
        @resizeColumn="handleResizeColumn"
        :bordered="true"
      >
        <template
          #customFilterDropdown="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }"
        >
          <div style="padding: 8px">
            <a-input
              ref="searchInput"
              :placeholder="`搜索 ${column.title}`"
              :value="selectedKeys[0]"
              style="width: 128px; margin-bottom: 8px; display: block"
              @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
              @pressEnter="handleSearch(selectedKeys, confirm, column.dataIndex)"
            />
            <a-button size="small" style="width: 60px; margin-right: 8px" @click="handleReset(clearFilters)">
              重置
            </a-button>
            <a-button
              type="primary"
              size="small"
              style="width: 60px;"
              @click="handleSearch(selectedKeys, confirm, column.dataIndex)"
            >
              <template #icon>
                <SearchOutlined/>
              </template>
              搜索
            </a-button>

          </div>
        </template>
        <template #customFilterIcon="{ filtered }">
          <search-outlined :style="{ fontSize:'15px',color: filtered ? '#108ee9' : undefined }"/>
        </template>
        <template #bodyCell="{ column, record }">
          <template v-if="column.dataIndex === 'operation'">
            <a v-if="record.img!=''" @click="showImg(record.img)">查看</a>
          </template>
        </template>
      </a-table>
    </div>
  </div>
  <a-modal v-model:visible="isShowImg" title="文件" :footer="null">
    <a-image-preview-group>
      <a-image v-for="(url,index) in imgUrls" :key="index" :width="100" :height="120" :src="url" style="object-fit: fill;height:120px;cursor: pointer;"/>
    </a-image-preview-group>
    <a-image v-for="(url,index) in fileUrls"  :key="index" :width="100" :height="120" :preview="false" style="cursor: pointer;" :src="fileUrl" @click="openFile(url)" />
  </a-modal>
</template>

<script>
import {defineComponent, onMounted, reactive, toRefs, ref} from 'vue';
import {useStore} from 'vuex';
import {SearchOutlined} from '@ant-design/icons-vue';

export default defineComponent({
  name: 'EcgData',
  components: {
    SearchOutlined
  },
  setup() {
    const store = useStore();
    const searchInput = ref();
    const columns = [
      {
        title: '姓名',
        dataIndex: 'name',
        width: '100px',
        customFilterDropdown: true,
        onFilter: (value, record) => record.name.toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
          if (visible) {
            setTimeout(() => {
              searchInput.value.focus();
            }, 100);
          }
        }
      }, {
        title: '手机号',
        dataIndex: 'phone',
        width: '150px',
        customFilterDropdown: true,
        onFilter: (value, record) => {
          if (record.phone) {
            return record.phone.toString().toLowerCase().includes(value.toLowerCase());
          } else {
            return false;
          }
        },
        onFilterDropdownVisibleChange: visible => {
          if (visible) {
            setTimeout(() => {
              searchInput.value.focus();
            }, 100);
          }
        }
      }, {
        title: '身份证号',
        dataIndex: 'idCard',
        width: '200px',
        customFilterDropdown: true,
        onFilter: (value, record) => {
          if (record.idCard) {
            return record.idCard.toString().toLowerCase().includes(value.toLowerCase());
          } else {
            return false;
          }
        },
        onFilterDropdownVisibleChange: visible => {
          if (visible) {
            setTimeout(() => {
              searchInput.value.focus();
            }, 100);
          }
        }
      }, {
        title: '反馈日期',
        dataIndex: 'takeTime',
        width: '200px'
      }, {
        title: '内容',
        dataIndex: 'content',
        width: '100px',
        ellipsis: true,
        resizable: true
      }, {
        title: '操作',
        dataIndex: 'operation',
        width: '100px'

      }
    ];
    const data = reactive({
      locale: {
        filterConfirm: '确定',
        filterReset: '重置',
        emptyText: '暂无数据'
      },
      columns,
      loading: false,
      pagination: {
        total: 0,
        pageSize: 10,
        page: 1,
        showSizeChanger: true
      },
      dataSource: [],
      imgUrls: [],
      fileUrls: [],
      fileUrl: require('../../../public/img/errImg.jpg'),
      isShowImg: false
    });
    const queryData = params => {
      store.dispatch('info/queryFeedBackData', params).then(res => {
        data.loading = false;
        if (res && res.data) {
          data.pagination.total = res.data.data.total;
          if (data.pagination.total > 0) {
            data.dataSource.push(...res.data.data.list);
          }
        }
      });
    };
    const handleTableChange = (pag, filters, sorter) => {
      data.dataSource = [];
      data.loading = true;
      data.pagination.page = pag?.current;
      data.pagination.pageSize = pag?.pageSize;
      data.pagination.total = 0;
      queryData({
        pageSize: pag.pageSize,
        page: pag?.current,
        sortField: sorter.field,
        sortOrder: sorter.order,
        ...filters
      });
    };
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
      confirm();
    };
    const handleReset = clearFilters => {
      clearFilters({
        confirm: true
      });
      // state.searchText = '';
    };
    const showImg = fileStr => {
      const list = fileStr.split(';');
      data.imgUrls = [];
      data.fileUrls = [];
      list.map((url) => {
        const suffix = url.substr(url.lastIndexOf('.'));
        const isImg = ['.png', '.jpg', '.jpeg', '.bmp', '.gif', '.webp', '.svg'].indexOf(suffix.toLowerCase()) !== -1;
        if (isImg) {
          data.imgUrls.push(url);
        } else {
          data.fileUrls.push(url);
        }
      });
      data.isShowImg = true;
    };
    const openFile = fileUrl => {
      window.open(fileUrl);
    };

    onMounted(() => {
      data.loading = true;
      queryData({
        pageSize: data.pagination.pageSize,
        page: data.pagination.page
      });
    });
    const handleResizeColumn = (w, col) => {
      col.width = w;
    };
    return {
      ...toRefs(data),
      searchInput,
      showImg,
      openFile,
      handleTableChange,
      handleSearch,
      handleReset,
      handleResizeColumn
    };
  }
});
</script>

<style lang="scss" scoped>
  @import '@/style/info/feedBack.scss';
</style>

<template>
  <div class="main">
    <div class="header">
      <a-button type="primary" @click="onAdd">新增</a-button>
      <!--<a-button type="primary" @click="onAuthorize">授权</a-button>  :rowSelection="rowSelection"-->
    </div>
    <div class="content">
      <a-table
        :columns="columns"
        :row-key="record => record.id"
        :data-source="dataSource"
        :pagination="pagination"
        :loading="loading"
        :locale="locale"
        :scroll="{ y: '75vh',x: '80vw'  }"
        @change="handleTableChange"
        :bordered="true"
      >
        <template
          #customFilterDropdown="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }"
        >
          <div style="padding: 8px">
            <a-input
              ref="searchInput"
              :placeholder="`搜索 ${column.title}`"
              :value="selectedKeys[0]"
              style="width: 128px; margin-bottom: 8px; display: block"
              @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
              @pressEnter="handleSearch(selectedKeys, confirm, column.dataIndex)"
            />
            <a-button size="small" style="width: 60px; margin-right: 8px" @click="handleReset(clearFilters)">
              重置
            </a-button>
            <a-button
              type="primary"
              size="small"
              style="width: 60px;"
              @click="handleSearch(selectedKeys, confirm, column.dataIndex)"
            >
              <template #icon>
                <SearchOutlined/>
              </template>
              搜索
            </a-button>

          </div>
        </template>
        <template #customFilterIcon="{ filtered }">
          <search-outlined :style="{ fontSize:'15px',color: filtered ? '#108ee9' : undefined }"/>
        </template>
        <template #bodyCell="{ column, record }">
          <template v-if="column.dataIndex === 'operation'">
            <a-button type="primary" @click="onEdit(record)" class="operation-btn">编辑</a-button>
            <a-button type="primary" @click="onAuthorize(record)" class="operation-btn">模块授权</a-button>
            <a-button type="primary" @click="onAuthorizeDevice(record)" class="operation-btn">设备授权</a-button>
          </template>
        </template>
        <template #expandedRowRender="{ record}">
          <a-table
            :columns="deviceColumns"
            :data-source="record.devices"
            :bordered="true"
            :pagination="false">
          </a-table>
        </template>
      </a-table>
    </div>
  </div>
  <a-modal v-model:visible="isShowDeviceWin" :title="deviceWinTitle" :footer="null" :width="550">
    <div class="module-tree">
      <a-transfer
        :data-source="deviceData"
        show-search
        :target-keys="targetDeviceKeys"
        :render="item => item.deviceName+'('+item.deviceId+')'"
        @change="handleDevicesChange"
      />
    </div>
    <div class="form-btns">
      <a-button type="primary" @click="onSubmitAuthDevice">完成</a-button>
    </div>
  </a-modal>
  <a-modal v-model:visible="isShowTree" :title="treeTitle" :footer="null" :width="300">
    <div class="module-tree">
    <a-tree
      checkable
      :tree-data="treeData"
      :replaceFields="treeFields"
      v-model:expandedKeys="expandedKeys"
      v-model:selectedKeys="selectedKeys"
      v-model:checkedKeys="checkedKeys"
      v-model:halfCheckedKeys="halfCheckedKeys"
    >
    </a-tree>
    </div>
    <div class="form-btns">
      <a-button type="primary" @click="onSubmitAuth">完成</a-button>
    </div>
  </a-modal>
  <a-modal v-model:visible="isShowAdd" :title="formState.title" :footer="null">
    <a-form
      ref="formRef"
      name="custom-validation"
      :model="formState"
      :rules="rules"
      v-bind="layout"
      @finish="handleFinish"
    >
      <div class="form-item">
        <a-form-item has-feedback label="账号" name="userId">
          <a-input v-model:value="formState.userId" autocomplete="off"/>
        </a-form-item>

      </div>
      <div class="form-item">
        <a-form-item has-feedback label="所属" name="name">
          <a-input v-model:value="formState.name" autocomplete="off"/>
        </a-form-item>
      </div>
      <div class="form-item">
        <a-form-item has-feedback label="驾驶舱标题" name="title">
          <a-input v-model:value="formState.title" autocomplete="off"/>
        </a-form-item>
      </div>
      <div class="form-item">
        <a-form-item has-feedback label="密码" name="pass">
          <a-input-password v-model:value="formState.pass" placeholder=""/>
        </a-form-item>
      </div>
      <div class="form-item">
        <a-form-item has-feedback label="确认密码" name="checkPass">
          <a-input-password v-model:value="formState.checkPass" placeholder=""/>
        </a-form-item>
      </div>
      <div class="form-btns">
        <a-button type="primary" html-type="submit">提交</a-button>
        <a-button style="margin-left: 20px" @click="resetForm">重置</a-button>
      </div>
    </a-form>
  </a-modal>
</template>

<script>
import {defineComponent, onMounted, reactive, toRefs, ref} from 'vue';
import {useStore} from 'vuex';
import {SearchOutlined} from '@ant-design/icons-vue';
import {message} from 'ant-design-vue';

export default defineComponent({
  name: 'User',
  components: {
    SearchOutlined
  },
  setup() {
    const store = useStore();
    const searchInput = ref();
    const columns = [
      {
        title: '序号',
        dataIndex: 'id',
        width: '60px',
        sortDirections: ['descend', 'ascend'],
        showSorterTooltip: false,
        sorter: true,
        defaultSortOrder: 'descend'
      },
      {
        title: '账号',
        dataIndex: 'userId',
        width: '100px',
        sorter: true,
        sortDirections: ['descend', 'ascend'],
        showSorterTooltip: false,
        customFilterDropdown: true,
        onFilter: (value, record) => record.userId.toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
          if (visible) {
            setTimeout(() => {
              searchInput.value.focus();
            }, 100);
          }
        }
      }, {
        title: '所属',
        dataIndex: 'name',
        width: '120px',
        sorter: true,
        sortDirections: ['descend', 'ascend'],
        showSorterTooltip: false,
        customFilterDropdown: true,
        onFilter: (value, record) => record.name.toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
          if (visible) {
            setTimeout(() => {
              searchInput.value.focus();
            }, 100);
          }
        }
      }, {
        title: '驾驶舱标题',
        dataIndex: 'title',
        width: '200px',
        sorter: true,
        sortDirections: ['descend', 'ascend'],
        showSorterTooltip: false,
        customFilterDropdown: true,
        onFilter: (value, record) => record.title.toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
          if (visible) {
            setTimeout(() => {
              searchInput.value.focus();
            }, 100);
          }
        }
      }, {
        title: '操作',
        dataIndex: 'operation',
        width: '150px'

      }
    ];
    const deviceColumns = [
      {
        title: '序号',
        dataIndex: 'id',
        width: '60px',
        sortDirections: ['descend', 'ascend'],
        showSorterTooltip: false,
        sorter: true,
        defaultSortOrder: 'descend'
      },
      {
        title: '设备编号',
        dataIndex: 'deviceId',
        width: '100px',
        sorter: true,
        sortDirections: ['descend', 'ascend'],
        showSorterTooltip: false,
        customFilterDropdown: true,
        onFilter: (value, record) => record.deviceId.toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
          if (visible) {
            setTimeout(() => {
              searchInput.value.focus();
            }, 100);
          }
        }
      }, {
        title: '设备名称',
        dataIndex: 'deviceName',
        width: '150px',
        sorter: true,
        sortDirections: ['descend', 'ascend'],
        showSorterTooltip: false,
        customFilterDropdown: true,
        onFilter: (value, record) => record.deviceName.toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
          if (visible) {
            setTimeout(() => {
              searchInput.value.focus();
            }, 100);
          }
        }
      }, {
        title: '设备型号',
        dataIndex: 'model',
        width: '150px',
        sorter: true,
        sortDirections: ['descend', 'ascend'],
        showSorterTooltip: false,
        customFilterDropdown: true,
        onFilter: (value, record) => record.model.toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
          if (visible) {
            setTimeout(() => {
              searchInput.value.focus();
            }, 100);
          }
        }
      }
    ];
    const data = reactive({
      locale: {
        filterConfirm: '确定',
        filterReset: '重置',
        emptyText: '暂无数据'
      },
      columns,
      deviceColumns,
      loading: false,
      pagination: {
        total: 0,
        pageSize: 10,
        page: 1,
        showSizeChanger: true
      },
      dataSource: [],
      isShowAdd: false,
      addTitle: '新增',
      isShowTree: false,
      treeTitle: '模块授权',
      treeFields: {children: 'modules', title: 'title', key: 'moduleId'},
      treeData: [],
      expandedKeys: [],
      selectedKeys: [],
      checkedKeys: {},
      halfCheckedKeys: [],
      selectedRowKeys: [],
      isShowDeviceWin: false,
      deviceWinTitle: '设备数据授权',
      deviceData: [],
      targetDeviceKeys: []
    });
      /*
       * const onTableSelect = (selectedRowKeys) => {
       *   console.log('selectedRowKeys changed: ', selectedRowKeys);
       *   data.selectedRowKeys = selectedRowKeys;
       * };
       * const rowSelection=reactive({
       *   hideDefaultSelections:true,
       *     type:'radio',
       *     onSelect:onTableSelect
       * });
       */
    const queryData = params => {
      store.dispatch('user/queryUserList', params).then(res => {
        data.loading = false;
        if (res && res.data) {
          data.pagination.total = res.data.data.total;
          if (data.pagination.total > 0) {
            data.dataSource.push(...res.data.data.list);
          }
        }
      });
    };
    const handleTableChange = (pag, filters, sorter) => {
      data.dataSource = [];
      data.loading = true;
      data.pagination.page = pag?.current;
      data.pagination.pageSize = pag?.pageSize;
      data.pagination.total = 0;
      queryData({
        pageSize: pag.pageSize,
        page: pag?.current,
        sortField: sorter.field,
        sortOrder: sorter.order,
        ...filters
      });
    };
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
      confirm();
    };
    const handleReset = clearFilters => {
      clearFilters({
        confirm: true
      });
    };
    const onEdit = record => {
      formState.title = '编辑用户';
      formState.opType = 'edit';
      formState.id = record.id;
      formState.userId = record.userId;
      formState.name = record.name;
      formState.title = record.title;
      data.isShowAdd = true;
    };
    const onAdd = () => {
      formState.title = '新增用户';
      formState.opType = 'add';
      formState.id = 0;
      formState.userId = '';
      formState.title = '';
      formState.name = '';
      formState.pass = '';
      formState.checkPass = '';
      data.isShowAdd = true;
    };
    const onAuthorizeDevice = record => {
      data.authUserId = record.userId;
      store.dispatch('system/queryUserDeviceData', {userId: record.userId}).then(res => {
        data.deviceData = res.data.data.deviceData;
        data.targetDeviceKeys = res.data.data.targetDeviceKeys;
        data.isShowDeviceWin = true;
      });
    };
    const handleDevicesChange = (keys, direction, moveKeys) => {
      // console.log(keys, direction, moveKeys);
      data.targetDeviceKeys = keys;
    };
    const onSubmitAuthDevice = () => {
      store.dispatch('system/submitAuthDevice', {userId: data.authUserId, checked: data.targetDeviceKeys}).then(res => {
        if (res && res.data && res.data.success) {
          data.dataSource = [];
          data.loading = true;
          queryData({
            pageSize: data.pagination.pageSize,
            page: data.pagination.page,
            sortField: 'id',
            sortOrder: 'descend'
          });
          data.isShowDeviceWin = false;
          const msg = '授权成功';
          message.success(msg);
        } else {
          const msg = '授权失败,请重试';
          message.error(msg);
        }
      });
    };
    const onAuthorize = record => {
      data.authUserId = record.userId;
      store.dispatch('user/queryModuleTreeData', {userId: record.userId}).then(res => {
        data.treeData = res.data.treeData;
        data.expandedKeys = res.data.halfChecked;
        data.selectedKeys = res.data.checked;
        data.checkedKeys = {checked: res.data.checked, halfChecked: res.data.halfChecked};
        data.isShowTree = true;
      });
    };
    const onSubmitAuth = () => {
      store.dispatch('user/submitAuth', {userId: data.authUserId, checked: data.checkedKeys}).then(res => {
        if (res && res.data && res.data.success) {
          data.isShowTree = false;
          const msg = '授权成功';
          message.success(msg);
        } else {
          const msg = '授权失败,请重试';
          message.error(msg);
        }
      });
    };
    onMounted(() => {
      queryData({
        pageSize: data.pagination.pageSize,
        page: data.pagination.page,
        sortField: 'id',
        sortOrder: 'descend'
      });
    });
    const formRef = ref();
    const formState = reactive({
      title: '新增用户',
      opType: 'add',
      userId: '',
      name: '',
      pass: '',
      checkPass: ''
    });
    const validatePass = async(rule, value) => {
      if (value === '') {
        return Promise.reject(new Error('请输入密码'));
      } else {
        if (formState.checkPass !== '') {
          formRef.value.validateFields('checkPass');
        }
        return Promise.resolve();
      }
    };
    const validatePass2 = async(rule, value) => {
      if (value === '') {
        return Promise.reject(new Error('确认输入密码'));
      } else if (value !== formState.pass) {
        return Promise.reject(new Error('两次输入的密码不一致!'));
      } else {
        return Promise.resolve();
      }
    };
    const rules = {
      pass: [
        {
          required: true,
          validator: validatePass,
          trigger: 'change'
        }
      ],
      checkPass: [
        {
          validator: validatePass2,
          trigger: 'change'
        }
      ],
      userId: [
        {
          required: true,
          message: '账号不能为空'
        }
      ],
      name: [
        {
          required: true,
          message: '所属不能为空'
        }
      ],
      title: [
        {
          required: true,
          message: '驾驶舱标题不能为空'
        }
      ]
    };
    const layout = {
      labelCol: {
        style: {
          width: '100px'
        }
      },
      wrapperCol: {
        span: 14
      }
    };
    const submitData = params => {
      store.dispatch('user/submitUserInfo', params).then(res => {
        if (res && res.data && res.data.success) {
          data.dataSource = [];
          data.pagination.page = 1;
          data.loading = true;
          queryData({
            pageSize: data.pagination.pageSize,
            page: data.pagination.page,
            sortField: 'id',
            sortOrder: 'descend'
          });
          data.isShowAdd = false;
          let msg = '新增成功';
          if (params.opType === 'edit') {
            msg = '编辑成功';
          }
          message.success(msg);
        } else {
          let msg = '新增失败,请重试';
          if (params.opType === 'edit') {
            msg = '编辑失败,请重试';
          }
          message.error(msg);
        }
      });
    };
    const handleFinish = values => {
      submitData(formState);
    };
    const resetForm = () => {
      formRef.value.resetFields();
    };
    return {
      ...toRefs(data),
      searchInput,
      handleTableChange,
      handleSearch,
      handleReset,
      onAdd,
      onEdit,
      onAuthorize,
      onAuthorizeDevice,
      handleDevicesChange,
      onSubmitAuthDevice,
      formState,
      formRef,
      rules,
      layout,
      handleFinish,
      resetForm,
      onSubmitAuth
    };
  }
});
</script>

<style lang="scss" scoped>
  @import '@/style/system/user.scss';
</style>

<template>
  <div class="main">
    <div class="header"></div>
    <div class="content">
      <a-table
        :columns="columns"
        :row-key="record => record.id"
        :data-source="dataSource"
        :pagination="pagination"
        :loading="loading"
        :locale="locale"
        :scroll="{ y: '70vh',x:'120vw' }"
        @change="handleTableChange"
        @resizeColumn="handleResizeColumn"
        :bordered="true"
      >
        <template
          #customFilterDropdown="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }"
        >
          <div style="padding: 8px">
            <a-input
              ref="searchInput"
              :placeholder="`搜索 ${column.title}`"
              :value="selectedKeys[0]"
              style="width: 128px; margin-bottom: 8px; display: block"
              @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
              @pressEnter="handleSearch(selectedKeys, confirm, column.dataIndex)"
            />
            <a-button size="small" style="width: 60px; margin-right: 8px" @click="handleReset(clearFilters)">
              重置
            </a-button>
            <a-button
              type="primary"
              size="small"
              style="width: 60px;"
              @click="handleSearch(selectedKeys, confirm, column.dataIndex)"
            >
              <template #icon>
                <SearchOutlined/>
              </template>
              搜索
            </a-button>

          </div>
        </template>
        <template #bodyCell="{ column, record }">
          <template v-if="column.dataIndex === 'checkAddress'">
            {{getAddress(record.checkAddress)}}
          </template>
        </template>
        <template #customFilterIcon="{ filtered }">
          <search-outlined :style="{ fontSize:'15px',color: filtered ? '#108ee9' : undefined }"/>
        </template>
        <template #expandedRowRender="{ record }">
          <p style="margin: 0">
            主要脉象类型：{{ record.pulseType }}
          </p>
          <p style="margin: 0">
            脉象类型说明：{{ record.pulseDescription }}
          </p>
          <p style="margin: 0">
            体质类型：{{ record.physiqueType }}
          </p>
          <p style="margin: 0">
            体质类型描述：{{ record.physiqueDescription }}
          </p>
          <p style="margin: 0" v-html="'调理建议：'+record.propose">
          </p>
        </template>
      </a-table>
    </div>
  </div>
</template>

<script>
import {defineComponent, onMounted, reactive, toRefs, ref} from 'vue';
import {useStore} from 'vuex';
import {SearchOutlined} from '@ant-design/icons-vue';

export default defineComponent({
  name: 'Member',
  components: {
    SearchOutlined
  },
  setup() {
    const store = useStore();
    const searchInput = ref();
    const columns = [
      {
        title: '姓名',
        dataIndex: 'name',
        width: 80,
        customFilterDropdown: true,
        onFilter: (value, record) => {
          if (record.name) {
            return record.name.toString().toLowerCase().includes(value.toLowerCase());
          } else {
            return false;
          }
        },
        onFilterDropdownVisibleChange: visible => {
          if (visible) {
            setTimeout(() => {
              searchInput.value.focus();
            }, 100);
          }
        }
      }, {
        title: '手机号',
        dataIndex: 'phone',
        width: 120,
        customFilterDropdown: true,
        onFilter: (value, record) => {
          if (record.phone) {
            return record.phone.toString().toLowerCase().includes(value.toLowerCase());
          } else {
            return false;
          }
        },
        onFilterDropdownVisibleChange: visible => {
          if (visible) {
            setTimeout(() => {
              searchInput.value.focus();
            }, 100);
          }
        }
      }, {
        title: '身份证号',
        dataIndex: 'idNumber',
        width: 200,
        customFilterDropdown: true,
        onFilter: (value, record) => {
          if (record.idCard) {
            return record.idCard.toString().toLowerCase().includes(value.toLowerCase());
          } else {
            return false;
          }
        },
        onFilterDropdownVisibleChange: visible => {
          if (visible) {
            setTimeout(() => {
              searchInput.value.focus();
            }, 100);
          }
        }
      }, {
        title: '性别',
        dataIndex: 'sex',
        width: 60
      }, {
        title: '检查日期',
        dataIndex: 'checkDate',
        width: 100
      }, {
        title: '检查地址',
        dataIndex: 'checkAddress',
        width: 250,
        ellipsis: true,
        resizable: true
      }, {
        title: '设备',
        dataIndex: 'deviceId',
        width: 100
      }, {
        title: '总体面相',
        dataIndex: 'ensemble',
        width: 100,
        ellipsis: true,
        resizable: true
      }, {
        title: '额头',
        dataIndex: 'forehead',
        width: 60
      }, {
        title: '鼻子',
        dataIndex: 'nose',
        width: 60
      }, {
        title: '脸颊',
        dataIndex: 'cheek',
        width: 60
      }, {
        title: '唇色',
        dataIndex: 'lipColor',
        width: 60
      }, {
        title: '舌色',
        dataIndex: 'tongueColor',
        width: 60
      }, {
        title: '苔色',
        dataIndex: 'coatingColor',
        width: 60
      }, {
      /*
       *   title: '主要脉象类型',
       *   dataIndex: 'pulseType',
       *   width: 120,
       *   ellipsis: true
       * }, {
       *   title: '脉象类型说明',
       *   dataIndex: 'pulseDescription',
       *   width: 120,
       *   ellipsis: true
       * }, {
       */
        title: '肾',
        dataIndex: 'kidney',
        width: 60
      }, {
        title: '肺',
        dataIndex: 'lungs',
        width: 60
      }, {
        title: '肝',
        dataIndex: 'liver',
        width: 60
      }, {
        title: '胆',
        dataIndex: 'gallbladder',
        width: 60
      }, {
        title: '胸乳',
        dataIndex: 'chest',
        width: 60
      }, {
        title: '心',
        dataIndex: 'heart',
        width: 60
      }, {
        title: '脾',
        dataIndex: 'spleen',
        width: 60
      }, {
        title: '胃',
        dataIndex: 'stomach',
        width: 60
      }, {
        title: '小肠',
        dataIndex: 'smallIntestine',
        width: 60
      }, {
        title: '大肠',
        dataIndex: 'largeIntestine',
        width: 60
      }, {
        title: '膀胱',
        dataIndex: 'bladder',
        width: 60
      }, {
        title: '脑',
        dataIndex: 'brain',
        width: 60
      /*
       * }, {
       *   title: '体质类型',
       *   dataIndex: 'physiqueType',
       *   width: 120,
       *   ellipsis: true
       * }, {
       *   title: '体质类型描述',
       *   dataIndex: 'physiqueDescription',
       *   width: 120,
       *   ellipsis: true
       */
      }
    ];
    const data = reactive({
      locale: {
        filterConfirm: '确定',
        filterReset: '重置',
        emptyText: '暂无数据'
      },
      columns,
      loading: false,
      pagination: {
        total: 0,
        pageSize: 10,
        page: 1,
        showSizeChanger: true
      },
      dataSource: []
    });
    const queryData = params => {
      store.dispatch('health/queryMxjkData', params).then(res => {
        data.loading = false;
        if (res && res.data) {
          data.pagination.total = res.data.data.total;
          if (data.pagination.total > 0) {
            data.dataSource.push(...res.data.data.list);
          }
        }
      });
    };
    const handleTableChange = (pag, filters, sorter) => {
      data.dataSource = [];
      data.loading = true;
      data.pagination.page = pag?.current;
      data.pagination.pageSize = pag?.pageSize;
      data.pagination.total = 0;
      queryData({
        pageSize: pag.pageSize,
        page: pag?.current,
        sortField: sorter.field,
        sortOrder: sorter.order,
        ...filters
      });
    };
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
      confirm();
    };
    const handleReset = clearFilters => {
      clearFilters({
        confirm: true
      });
      // state.searchText = '';
    };

    onMounted(() => {
      queryData({
        pageSize: data.pagination.pageSize,
        page: data.pagination.page
      });
    });
    const handleResizeColumn = (w, col) => {
      col.width = w;
    };
    const getAddress=(address)=>{
      let json=JSON.parse(address.replaceAll('&quot;','"'));
      return  json.province+json.city+json.area+json.street;
    };
    return {
      ...toRefs(data),
      searchInput,
      handleTableChange,
      handleSearch,
      handleReset,
      handleResizeColumn,
      getAddress
    };
  }
});
</script>

<style lang="scss" scoped>
  @import '@/style/health/mxjkData.scss';
</style>

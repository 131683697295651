<template>
  <div class="main">
    <div class="header">
      <a-button type="primary" @click="onPrint">打印</a-button>
    </div>
    <form id="myForm" :action="url" method="post" target="iframe">
      <input type="hidden" name="userId" :value="userId"/>
      <input type="hidden" name="examRecordId" :value="examRecordId"/>
    </form>
    <iframe name="iframe" id="printIframe" width="100%" height="100%" frameborder="0" scrolling="auto"/>
  </div>
</template>

<script>
import {defineComponent, onMounted, reactive, toRefs} from 'vue';

export default defineComponent({
  name: 'YjReportDetail',
  /*
   * directives: {
   *   print
   * },
   */
  props: {
    userId: String,
    examRecordId: String,
    name: String
  },
  setup(props) {
    const data = reactive({
      url: 'huiyitec/wechat/BingHuaInterface',
      // url: 'http://huiyitecwechat.huiyitec.cn/wechat/BingHuaInterface',
      userId: props.userId,
      examRecordId: props.examRecordId
    });
    onMounted(() => {
      const from = document.getElementById('myForm');
      from.submit();
    });
    const onPrint = () => {
      const iframe = document.frames ? document.frames.printIframe : document.getElementById('printIframe');
      iframe.contentWindow.document.getElementsByClassName('iconDe')[0].style.display = 'none';
      iframe.contentWindow.focus();
      iframe.contentWindow.print();
    };
    return {
      ...toRefs(data),
      onPrint
    };
  }
});
</script>

<style lang="scss" scoped>
  @import '@/style/common/YjReportDetail.scss';
</style>

/**
 * article模块接口列表
 */
// 导入接口域名列表
import base from './base';
// 导入http中创建的axios实例
import axios from '../utils/http';
import qs from 'qs';

const cmsApi = {
  // 登录
  login(username, password) {
    const params = qs.stringify({
      grant_type: 'client_credentials',
      client_id: username,
      client_secret: password
    });
    return axios.post(`${base.cmsApi}/oauth/token`, params);
  },
  loginByToken(token) {
    const params = qs.stringify({
      token
    });
    return axios.post(`${base.cmsApi}/cms/system/loginByToken`, params);
  },
  // 修改密码
  updateUserPass(params) {
    const param = qs.stringify(params);
    return axios.post(`${base.cmsApi}/cms/system/user/updateUserPass`, param);
  },
  // 查询模块权限
  queryModule() {
    return axios.post(`${base.cmsApi}/cms/system/queryModule`);
  },
  // 查询用户模块权限
  queryModuleTreeData(params) {
    const param = qs.stringify(params);
    return axios.post(`${base.cmsApi}/cms/system/queryModuleTreeData`, param);
  },
  // 提交用户模块权限
  submitAuth(params) {
    const param = qs.stringify({jsonStr: JSON.stringify(params)});
    return axios.post(`${base.cmsApi}/cms/system/submitAuth`, param);
  },
  queryUserList(params) {
    const param = qs.stringify({jsonStr: JSON.stringify(params)});
    return axios.post(`${base.cmsApi}/cms/system/queryUserList`, param);
  },
  submitUserInfo(params) {
    const param = qs.stringify({jsonStr: JSON.stringify(params)});
    return axios.post(`${base.cmsApi}/cms/system/user/submit`, param);
  },
  // 查询注册人员列表
  queryPersonelData(params) {
    const param = qs.stringify({jsonStr: JSON.stringify(params)});
    return axios.post(`${base.cmsApi}/cms/personel/query`, param);
  },
  // 查询成员列表
  queryPersonelMemberData(params) {
    const param = qs.stringify({jsonStr: JSON.stringify(params)});
    return axios.post(`${base.cmsApi}/cms/personel/member/query`, param);
  },
  deletePersonelMemberData(params) {
    const param = qs.stringify(params);
    return axios.post(`${base.cmsApi}/cms/personel/member/delete`, param);
  },
  queryHealthData(params) {
    const param = qs.stringify({jsonStr: JSON.stringify(params)});
    return axios.post(`${base.cmsApi}/cms/healthData/list/query`, param);
  },
  queryYjReportData(params) {
    const param = qs.stringify({jsonStr: JSON.stringify(params)});
    return axios.post(`${base.cmsApi}/cms/yjReport/list/query`, param);
  },
  queryEcgData(params) {
    const param = qs.stringify({jsonStr: JSON.stringify(params)});
    return axios.post(`${base.cmsApi}/cms/ecgData/list/query`, param);
  },
  queryMxjkData(params) {
    const param = qs.stringify({jsonStr: JSON.stringify(params)});
    return axios.post(`${base.cmsApi}/cms/mxjkData/list/query`, param);
  },
  queryFeedBackData(params) {
    const param = qs.stringify({jsonStr: JSON.stringify(params)});
    return axios.post(`${base.cmsApi}/cms/feedBack/list/query`, param);
  },
  // 申诉列表
  queryAppealData(params) {
    const param = qs.stringify({jsonStr: JSON.stringify(params)});
    return axios.post(`${base.cmsApi}/cms/appeal/list/query`, param);
  },
  updateAppealState(params) {
    const param = qs.stringify(params);
    return axios.post(`${base.cmsApi}/cms/appeal/state/update`, param);
  },
  queryCostData(params) {
    const param = qs.stringify({jsonStr: JSON.stringify(params)});
    return axios.post(`${base.cmsApi}/cms/ecg/cost/list/query`, param);
  },
  submitCost(params) {
    const param = qs.stringify({jsonStr: JSON.stringify(params)});
    return axios.post(`${base.cmsApi}/cms/ecg/cost/submit`, param);
  },
  deleteCostData(params) {
    const param = qs.stringify(params);
    return axios.post(`${base.cmsApi}/cms/ecg/cost/delete`, param);
  },
  updateSurplusNum(params) {
    const param = qs.stringify(params);
    return axios.post(`${base.cmsApi}/cms/ecg/surplus/updateNum`, param);
  },
  queryBillData(params) {
    const param = qs.stringify({jsonStr: JSON.stringify(params)});
    return axios.post(`${base.cmsApi}/cms/ecg/bill/list/query`, param);
  },
  queryOrderData(params) {
    const param = qs.stringify({jsonStr: JSON.stringify(params)});
    return axios.post(`${base.cmsApi}/cms/ecg/vipOrder/list/query`, param);
  },
  queryDeviceList(params) {
    const param = qs.stringify({jsonStr: JSON.stringify(params)});
    return axios.post(`${base.cmsApi}/cms/system/device/list/query`, param);
  },
  // 添加设备
  submitDeviceInfo(params) {
    const param = qs.stringify({jsonStr: JSON.stringify(params)});
    return axios.post(`${base.cmsApi}/cms/system/device/submit`, param);
  },
  // 删除设备
  deleteDevice(params) {
    const param = qs.stringify(params);
    return axios.post(`${base.cmsApi}/cms/system/device/delete`, param);
  },
  // 查询用户设备权限
  queryUserDeviceData(params) {
    const param = qs.stringify(params);
    return axios.post(`${base.cmsApi}/cms/system/device/queryUserDeviceData`, param);
  },
  // 提交用户设备权限
  submitAuthDevice(params) {
    const param = qs.stringify({jsonStr: JSON.stringify(params)});
    return axios.post(`${base.cmsApi}/cms/system/device/submitAuthDevice`, param);
  },
  queryChartData() {
    return axios.post(`${base.cmsApi}/cms/chart/queryChartData`);
  },
  // 查询单位数据
  queryUnitData(params) {
    const param = qs.stringify({jsonStr: JSON.stringify(params)});
    return axios.post(`${base.cmsApi}/cms/chart/unit/list/query`, param);
  },
  // 新增编辑单位数据
  submitUnit(params) {
    const param = qs.stringify({jsonStr: JSON.stringify(params)});
    return axios.post(`${base.cmsApi}/cms/chart/unit/submit`, param);
  },
  // 删除单位数据
  deleteUnitData(params) {
    const param = qs.stringify(params);
    return axios.post(`${base.cmsApi}/cms/chart/unit/delete`, param);
  },
  // 查询活动数据
  queryActivityData(params) {
    const param = qs.stringify({jsonStr: JSON.stringify(params)});
    return axios.post(`${base.cmsApi}/cms/chart/activity/list/query`, param);
  },
  // 新增编辑活动数据
  submitActivity(params) {
    const param = qs.stringify({jsonStr: JSON.stringify(params)});
    return axios.post(`${base.cmsApi}/cms/chart/activity/submit`, param);
  },
  // 删除活动数据
  deleteActivityData(params) {
    const param = qs.stringify(params);
    return axios.post(`${base.cmsApi}/cms/chart/activity/delete`, param);
  },
  // 查询其他数据
  queryOtherData(params) {
    const param = qs.stringify({jsonStr: JSON.stringify(params)});
    return axios.post(`${base.cmsApi}/cms/chart/other/list/query`, param);
  },
  // 新增编辑其他数据
  submitOtherData(params) {
    const param = qs.stringify({jsonStr: JSON.stringify(params)});
    return axios.post(`${base.cmsApi}/cms/chart/other/submit`, param);
  },
  // 删除其他数据
  deleteOtherData(params) {
    const param = qs.stringify(params);
    return axios.post(`${base.cmsApi}/cms/chart/other/delete`, param);
  },
  queryHisDataUploadInfo(params) {
    const param = qs.stringify({jsonStr: JSON.stringify(params)});
    return axios.post(`${base.cmsApi}/cms/his/docking/list/query`, param);
  },
  doHisDataUpload(params) {
    const param = qs.stringify({jsonStr: JSON.stringify(params)});
    return axios.post(`${base.cmsApi}/cms/his/docking/dataUpload`, param);
  }
};

export default cmsApi;

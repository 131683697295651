import api from '../../api';

const state = () => ({
});

// getters
const getters = {};

// actions
const actions = {
  queryDeviceList(context, params) {
    return new Promise((resolve, reject) => {
      api.cmsApi.queryDeviceList(params).then(res => {
        resolve(res);
      });
    });
  },
  queryUserDeviceData(context, params) {
    return new Promise((resolve, reject) => {
      api.cmsApi.queryUserDeviceData(params).then(res => {
        resolve(res);
      });
    });
  },
  submitDeviceInfo(context, params) {
    return new Promise((resolve, reject) => {
      api.cmsApi.submitDeviceInfo(params).then(res => {
        resolve(res);
      });
    });
  },
  deleteDevice(context, params) {
    return new Promise((resolve, reject) => {
      api.cmsApi.deleteDevice(params).then(res => {
        resolve(res);
      });
    });
  },
  submitAuthDevice(context, params) {
    return new Promise((resolve, reject) => {
      api.cmsApi.submitAuthDevice(params).then(res => {
        resolve(res);
      });
    });
  }
};

// mutations
const mutations = {
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

<template>
  <vue-particles
    color="#6495ED"
    :particleOpacity="0.7"
    :particlesNumber="80"
    shapeType="circle"
    :particleSize="4"
    linesColor="#6495ED"
    :linesWidth="1"
    :lineLinked="true"
    :lineOpacity="0.6"
    :linesDistance="150"
    :moveSpeed="3"
    :hoverEffect="true"
    hoverMode="grab"
    :clickEffect="true"
    clickMode="push"
    class="body login"
  >

  </vue-particles>
    <div class="login-window">
      <div class="title">敏智维心管理平台</div>
      <a-form
        :model="formState"
        name="normal_login"
        class="login-form"
        @finish="onFinish"
        @finishFailed="onFinishFailed"
      >
        <div class="login-form-title">普通登录</div>
        <a-form-item
          name="username"
        >
          <a-input v-model:value="formState.username" :bordered="false" placeholder="请输入您的账号" size="large"
                   class="login-form-text">
            <!--<template #prefix>-->
            <!--<UserOutlined class="site-form-item-icon" />-->
            <!--</template>-->
          </a-input>
        </a-form-item>

        <a-form-item
          name="password"
        >
          <a-input v-model:value="formState.password" :bordered="false" placeholder="请输入您的密码" type="password"
                   size="large" class="login-form-text">
            <!--<template #prefix>-->
            <!--<LockOutlined class="site-form-item-icon" />-->
            <!--</template>-->
          </a-input>
        </a-form-item>
        <a-form-item>
          <a-button :disabled="disabled" type="primary" html-type="submit"
                    :class="{'login-form-button':true,'button-disable':disabled}">
            登录
          </a-button>
        </a-form-item>
      </a-form>
    </div>
</template>
<script>
import {defineComponent, reactive, computed, toRefs} from 'vue';
import {useStore} from 'vuex';
import {useRouter, useRoute} from 'vue-router';

export default defineComponent({
  components: {},
  async setup() {
    const router = useRouter();
    const store = useStore();
    const data = reactive({
      formState: {
        username: '',
        password: ''
      },
      background_src: require('../../public/img/background.png')
    });
    const route = useRoute();
    const token = route.query.access_token;
    if (token) {
      const {success} = await store.dispatch('user/doLoginByToken', {token});
      if (success) {
        router.push('/index');
      } else {
        window.location.href = 'http://platweb.liferichin.com/login';
      }
    }
    const onFinish = values => {
      store.dispatch('user/doLogin', values).then(res => {
        router.push('/index');
      });
      // console.log('Success:', values);
    };
    const onFinishFailed = errorInfo => {
      // console.log('Failed:', errorInfo);
    };
    const disabled = computed(() => {
      return !(data.formState.username && data.formState.password);
    });
    return {
      ...toRefs(data),
      onFinish,
      onFinishFailed,
      disabled
    };
  }
});
</script>
<style lang="scss" scoped>
  @import '@/style/login.scss';
</style>

<template>
  <div class="main">
    <div class="content">
      <a-table
        :columns="columns"
        :row-key="record => record.id"
        :data-source="dataSource"
        :pagination="pagination"
        :loading="loading"
        :locale="locale"
        :scroll="{ y: '70vh',x:'80vw' }"
        @change="handleTableChange"
        @resizeColumn="handleResizeColumn"
        :bordered="true"
      >
        <template
          #customFilterDropdown="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }"
        >
          <div style="padding: 8px">
            <a-input
              ref="searchInput"
              :placeholder="`搜索 ${column.title}`"
              :value="selectedKeys[0]"
              style="width: 128px; margin-bottom: 8px; display: block"
              @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
              @pressEnter="handleSearch(selectedKeys, confirm, column.dataIndex)"
            />
            <a-button size="small" style="width: 60px; margin-right: 8px" @click="handleReset(clearFilters)">
              重置
            </a-button>
            <a-button
              type="primary"
              size="small"
              style="width: 60px;"
              @click="handleSearch(selectedKeys, confirm, column.dataIndex)"
            >
              <template #icon>
                <SearchOutlined/>
              </template>
              搜索
            </a-button>
          </div>
        </template>
        <template #customFilterIcon="{ filtered }">
          <search-outlined :style="{ fontSize:'15px',color: filtered ? '#108ee9' : undefined }"/>
        </template>
        <template #bodyCell="{ column, record }">
          <template v-if="column.dataIndex === 'operation'">
            <a @click="showDetail(record)">查看报告</a>
          </template>
          <template v-if="column.dataIndex === 'sex'">
            <p>
              {{ record.sex==1?'男':'女' }}
            </p>
          </template>
          <template v-else-if="column.dataIndex === 'conditions'">
            <p>
              {{ record.smoke==1?'吸烟':'' }}
              {{ record.drink==1?'喝酒':'' }}
              {{ record.depressor==1?'服用降压药物':'' }}
              {{ record.antidiabetic==1?'服用降糖药物':'' }}
              {{ record.lipidLowering==1?'服用降脂药物':'' }}
              {{ record.health==1?'服用保健药物':'' }}
            </p>
          </template>
        </template>
      </a-table>
    </div>
  </div>
</template>

<script>
import {defineComponent, onMounted, reactive, toRefs, ref} from 'vue';
import {useStore} from 'vuex';
import {SearchOutlined} from '@ant-design/icons-vue';

export default defineComponent({
  name: 'YjReport',
  components: {
    SearchOutlined
  },
  emits: ['openYjDetail'],
  setup(props, ctx) {
    const store = useStore();
    const searchInput = ref();
    const columns = [
      {
        title: '序号',
        dataIndex: 'id',
        // sorter: true,
        width: 80,
        defaultSortOrder: 'descend'
      },
      {
        title: '姓名',
        dataIndex: 'name',
        width: 100,
        customFilterDropdown: true,
        onFilter: (value, record) => {
          if (record.name) {
            return record.name.toString().toLowerCase().includes(value.toLowerCase());
          } else {
            return false;
          }
        },
        onFilterDropdownVisibleChange: visible => {
          if (visible) {
            setTimeout(() => {
              searchInput.value.focus();
            }, 100);
          }
        }
      }, {
        title: '手机号',
        dataIndex: 'phone',
        width: 150,
        customFilterDropdown: true,
        onFilter: (value, record) => {
          if (record.phone) {
            return record.phone.toString().toLowerCase().includes(value.toLowerCase());
          } else {
            return false;
          }
        },
        onFilterDropdownVisibleChange: visible => {
          if (visible) {
            setTimeout(() => {
              searchInput.value.focus();
            }, 100);
          }
        }
      }, {
        title: '身份证号',
        dataIndex: 'idCard',
        width: 200,
        customFilterDropdown: true,
        onFilter: (value, record) => {
          if (record.idCard) {
            return record.idCard.toString().toLowerCase().includes(value.toLowerCase());
          } else {
            return false;
          }
        },
        onFilterDropdownVisibleChange: visible => {
          if (visible) {
            setTimeout(() => {
              searchInput.value.focus();
            }, 100);
          }
        }
      }, {
        title: '性别',
        dataIndex: 'sex',
        filters: [
          {
            text: '男',
            value: '1'
          }, {
            text: '女',
            value: '2'
          }
        ],
        width: 100
      }, {
        title: '出生日期',
        dataIndex: 'formattedBirthday',
        width: 100
      }, {
        title: '身高(cm)',
        dataIndex: 'height',
        width: 100
      }, {
        title: '体重(kg)',
        dataIndex: 'weight',
        width: 100
      }, {
        title: 'BMI',
        dataIndex: 'bmi',
        width: 80
      }, {
        title: '心率',
        dataIndex: 'heart',
        width: 80
      }, {
        title: '生活情况',
        dataIndex: 'conditions',
        filters: [
          {
            text: '吸烟',
            value: 'smoke'
          },
          {
            text: '饮酒',
            value: 'drink'
          },
          {
            text: '服用降压药',
            value: 'depressor'
          },
          {
            text: '服用降糖药',
            value: 'antidiabetic'
          },
          {
            text: '服用降脂药',
            value: 'lipidLowering'
          },
          {
            text: '服用保健药',
            value: 'health'
          }
        ]
      }, {
        title: '操作',
        dataIndex: 'operation',
        width: '100px'

      }
    ];
    const data = reactive({
      locale: {
        filterConfirm: '确定',
        filterReset: '重置',
        emptyText: '暂无数据'
      },
      columns,
      typeStr: ['影像检查', '化验检验', '门诊病历', '住院病历', '药品处方', '其他'],
      loading: false,
      pagination: {
        total: 0,
        pageSize: 10,
        page: 1,
        showSizeChanger: true
      },
      dataSource: []
    });
    const queryData = params => {
      store.dispatch('health/queryYjReportData', params).then(res => {
        data.loading = false;
        if (res && res.data) {
          data.pagination.total = res.data.data.total;
          if (data.pagination.total > 0) {
            data.dataSource.push(...res.data.data.list);
          }
        }
      });
    };
    const handleTableChange = (pag, filters, sorter) => {
      data.dataSource = [];
      data.loading = true;
      data.pagination.page = pag?.current;
      data.pagination.pageSize = pag?.pageSize;
      data.pagination.total = 0;
      queryData({
        pageSize: pag.pageSize,
        page: pag?.current,
        sortField: sorter.field,
        sortOrder: sorter.order,
        ...filters
      });
    };
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
      confirm();
    };
    const handleReset = clearFilters => {
      clearFilters({
        confirm: true
      });
      // state.searchText = '';
    };
    onMounted(() => {
      queryData({
        pageSize: data.pagination.pageSize,
        page: data.pagination.page
      });
    });
    const handleResizeColumn = (w, col) => {
      col.width = w;
    };

    const showDetail = (record) => {
      ctx.emit('openYjDetail', record);
    };
    return {
      ...toRefs(data),
      searchInput,
      handleTableChange,
      handleSearch,
      handleReset,
      handleResizeColumn,
      showDetail
    };
  }
});
</script>

<style lang="scss" scoped>
  @import '@/style/health/YjReport.scss';
</style>

<template>
  <div class="main">
    <div class="header"></div>
    <div class="content">
      <a-table
        :columns="columns"
        :row-key="record => record.id"
        :data-source="dataSource"
        :pagination="pagination"
        :loading="loading"
        :locale="locale"
        :scroll="{ y: '75vh' }"
        @change="handleTableChange"
        :bordered="true"
      >
        <template
          #customFilterDropdown="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }"
        >
          <div style="padding: 8px">
            <a-input
              ref="searchInput"
              :placeholder="`搜索 ${column.title}`"
              :value="selectedKeys[0]"
              style="width: 128px; margin-bottom: 8px; display: block"
              @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
              @pressEnter="handleSearch(selectedKeys, confirm, column.dataIndex)"
            />
            <a-button size="small" style="width: 60px; margin-right: 8px" @click="handleReset(clearFilters)">
              重置
            </a-button>
            <a-button
              type="primary"
              size="small"
              style="width: 60px;"
              @click="handleSearch(selectedKeys, confirm, column.dataIndex)"
            >
              <template #icon>
                <SearchOutlined/>
              </template>
              搜索
            </a-button>

          </div>
        </template>
        <template #customFilterIcon="{ filtered }">
          <search-outlined :style="{ fontSize:'15px',color: filtered ? '#108ee9' : undefined }"/>
        </template>
        <template #bodyCell="{ column, record }">
          <template v-if="column.dataIndex === 'operation'">
            <a @click="addNum(record)">增加</a>
          </template>
        </template>
      </a-table>
    </div>
  </div>
  <a-modal v-model:visible="isShowAdd" title="添加" :footer="null">
    <a-form
      ref="formRef"
      name="custom-validation"
      :model="formState"
      v-bind="layout"
      @finish="handleFinish"
    >
      <div class="form-item">
        <a-form-item label="收费类型" name="type">
          <a-select
            ref="select"
            v-model:value="formState.type"
            @change="handleTypeChange"
          >
            <a-select-option value="0">添加天数</a-select-option>
            <a-select-option value="1">添加次数(常规)</a-select-option>
            <a-select-option value="2">添加次数(综合)</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item has-feedback :label="label" name="num">
          <a-input-number :min="1" :addon-after="labelAfter" v-model:value="formState.num"/>
        </a-form-item>
      </div>

      <div class="form-btns">
        <a-button type="primary" html-type="submit">提交</a-button>
        <a-button style="margin-left: 20px" @click="resetForm">重置</a-button>
      </div>
    </a-form>
  </a-modal>
</template>

<script>
import {defineComponent, onMounted, reactive, toRefs, ref} from 'vue';
import {useStore} from 'vuex';
import {SearchOutlined} from '@ant-design/icons-vue';
import {message} from 'ant-design-vue';
import {calDay} from '@/utils/util.js';

export default defineComponent({
  name: 'Personnel',
  components: {
    SearchOutlined
  },
  setup() {
    const store = useStore();
    const searchInput = ref();
    const columns = [
      {
        title: 'ID',
        dataIndex: 'id',
        // sorter: true,
        width: '50px',
        defaultSortOrder: 'descend'
      },
      {
        title: '用户ID',
        dataIndex: 'userId',
        width: '150px'
      },
      {
        title: '姓名',
        dataIndex: 'name',
        width: '100px',
        customFilterDropdown: true,
        onFilter: (value, record) => record.name.toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
          if (visible) {
            setTimeout(() => {
              searchInput.value.focus();
            }, 100);
          }
        }
      }, {
        title: '手机号',
        dataIndex: 'mobile',
        width: '150px',
        customFilterDropdown: true,
        onFilter: (value, record) => {
          if (record.mobile) {
            return record.mobile.toString().toLowerCase().includes(value.toLowerCase());
          } else {
            return false;
          }
        },
        onFilterDropdownVisibleChange: visible => {
          if (visible) {
            setTimeout(() => {
              searchInput.value.focus();
            }, 100);
          }
        }
      }, {
        title: '身份证号',
        dataIndex: 'idCard',
        width: '200px',
        customFilterDropdown: true,
        onFilter: (value, record) => {
          if (record.idCard) {
            return record.idCard.toString().toLowerCase().includes(value.toLowerCase());
          } else {
            return false;
          }
        },
        onFilterDropdownVisibleChange: visible => {
          if (visible) {
            setTimeout(() => {
              searchInput.value.focus();
            }, 100);
          }
        }
      }, {
        title: '会员情况',
        dataIndex: 'vipTypeStr',
        width: '100px'
      }, {
        title: '剩余',
        dataIndex: 'surplus',
        width: '100px'
      }, {
        title: '操作',
        dataIndex: 'operation',
        width: '100px'

      }
    ];
    const data = reactive({
      label: '次数',
      labelAfter: '次',
      locale: {
        filterConfirm: '确定',
        filterReset: '重置',
        emptyText: '暂无数据'
      },
      columns,
      loading: false,
      filters: {},
      pagination: {
        total: 0,
        pageSize: 10,
        page: 1,
        showSizeChanger: true
      },
      dataSource: [],
      isShowAdd: false
    });
    const queryData = params => {
      store.dispatch('personel/queryData', params).then(res => {
        data.loading = false;
        if (res && res.data) {
          data.pagination.total = res.data.data.total;
          if (data.pagination.total > 0) {
            let list = res.data.data.list;
            list = list.map((item) => {
              const days = calDay(item.vipDate);
              return {
                ...item,
                ...{
                  days,
                  surplus: days > 0 ? days + '天' : (item.vipNum > 0 ? item.vipNum + '次' : '无'),
                  vipTypeStr: days > 0 ? '按时间计算' : (item.vipNum > 0 ? '按次数计算' : '已过期')
                }
              };
            });
            data.dataSource.push(...list);
          }
        }
      });
    };
    const handleTableChange = (pag, filters, sorter) => {
      data.dataSource = [];
      data.loading = true;
      data.pagination.page = pag?.current;
      data.pagination.pageSize = pag?.pageSize;
      data.pagination.total = 0;
      data.filters = filters;
      queryData({
        pageSize: pag.pageSize,
        page: pag?.current,
        ...filters
      });
      /*
       * sortField: sorter.field,
       *   sortOrder: sorter.order,
       */
    };
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
      confirm();
    };
    const handleReset = clearFilters => {
      clearFilters({
        confirm: true
      });
      // state.searchText = '';
    };
    const formRef = ref();
    const formState = reactive({
      userId: '',
      type: '1',
      num: 1
    });
    const layout = {
      labelCol: {
        style: {
          width: '80px'
        }
      },
      wrapperCol: {
        span: 14
      }
    };
    const submitData = params => {
      store.dispatch('ecg/updateSurplusNum', params).then(res => {
        if (res && res.data && res.data.success) {
          data.dataSource = [];
          // data.pagination.page = 1;
          data.loading = true;
          queryData({
            pageSize: data.pagination.pageSize,
            page: data.pagination.page,
            ...data.filters
          });
          data.isShowAdd = false;
          message.success('修改成功');
        } else {
          message.error('修改失败,请重试');
        }
      });
    };
    const handleFinish = values => {
      submitData(formState);
    };
    const resetForm = () => {
      formRef.value.resetFields();
    };
    const handleTypeChange = type => {
      switch (type) {
        case '0':
          data.label = '天数';
          data.labelAfter = '天';
          break;
        case '1':
        default:
        case '2':
          data.label = '次数';
          data.labelAfter = '次';
          break;
      }
    };

    onMounted(() => {
      queryData({
        pageSize: data.pagination.pageSize,
        page: data.pagination.page
      });
    });

    const addNum = record => {
      formState.userId = record.userId;
      formState.type = '1';
      formState.num = 1;
      data.isShowAdd = true;
    };
    return {
      ...toRefs(data),
      searchInput,
      handleTableChange,
      handleSearch,
      handleReset,
      addNum,
      formState,
      formRef,
      layout,
      handleTypeChange,
      handleFinish,
      resetForm
    };
  }
});
</script>

<style lang="scss" scoped>
  @import '@/style/ecg/surplus.scss';
</style>

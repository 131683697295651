import api from '../../api';

const state = () => ({
});

// getters
const getters = {};

// actions
const actions = {
  queryChartData(context) {
    return new Promise((resolve) => {
      api.cmsApi.queryChartData().then(res => {
        resolve(res);
      });
    });
  },
  queryUnitData(context, params) {
    return new Promise((resolve, reject) => {
      api.cmsApi.queryUnitData(params).then(res => {
        resolve(res);
      });
    });
  },
  submitUnit(context, params) {
    return new Promise((resolve, reject) => {
      api.cmsApi.submitUnit(params).then(res => {
        resolve(res);
      });
    });
  },
  deleteUnitData(context, params) {
    return new Promise((resolve, reject) => {
      api.cmsApi.deleteUnitData(params).then(res => {
        resolve(res);
      });
    });
  },
  queryActivityData(context, params) {
    return new Promise((resolve, reject) => {
      api.cmsApi.queryActivityData(params).then(res => {
        resolve(res);
      });
    });
  },
  submitActivity(context, params) {
    return new Promise((resolve, reject) => {
      api.cmsApi.submitActivity(params).then(res => {
        resolve(res);
      });
    });
  },
  deleteActivityData(context, params) {
    return new Promise((resolve, reject) => {
      api.cmsApi.deleteActivityData(params).then(res => {
        resolve(res);
      });
    });
  },
  queryOtherData(context, params) {
    return new Promise((resolve, reject) => {
      api.cmsApi.queryOtherData(params).then(res => {
        resolve(res);
      });
    });
  },
  submitOtherData(context, params) {
    return new Promise((resolve, reject) => {
      api.cmsApi.submitOtherData(params).then(res => {
        resolve(res);
      });
    });
  },
  deleteOtherData(context, params) {
    return new Promise((resolve, reject) => {
      api.cmsApi.deleteOtherData(params).then(res => {
        resolve(res);
      });
    });
  }
};

// mutations
const mutations = {
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

import api from '../../api';

const state = () => ({
});

// getters
const getters = {};

// actions
const actions = {
  queryFeedBackData(context, params) {
    return new Promise((resolve, reject) => {
      api.cmsApi.queryFeedBackData(params).then(res => {
        resolve(res);
      });
    });
  },
  queryAppealData(context, params) {
    return new Promise((resolve, reject) => {
      api.cmsApi.queryAppealData(params).then(res => {
        resolve(res);
      });
    });
  },
  updateAppealState(context, params) {
    return new Promise((resolve, reject) => {
      api.cmsApi.updateAppealState(params).then(res => {
        resolve(res);
      });
    });
  }
};

// mutations
const mutations = {
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
